import React, { Component } from "react";
import { connect } from 'react-redux';

import { showSignIn, hideSignIn } from '../Actions/User';
import SignInDialog from './SignInDialog';

class SignIn extends Component{
    constructor(props){
        super(props);
        this.handleClickOpen = this.handleClickOpen.bind(this);
        this.handleClose = this.handleClose.bind(this);
    }

    handleClickOpen(event){
        event.preventDefault();
        this.props.showSignIn();
    }

    handleClose(){
        this.props.hideSignIn();
    }

    render(){
        return(
            <>
                <a href={'/'} style={styles.regLink} onClick={this.handleClickOpen}>Inicia sesión</a>
                <SignInDialog 
                    open={this.props.signInOpen} 
                    onClose={this.handleClose}
                />
            </>
        )
    }
}

const styles = {
    regLink:{
        color: 'white',
        textDecoration: 'none'
    }
};

const mapStateToProps = ({ User }) => {
    return { 
        signInOpen: User.signInOpen
    }
}

const mapDispatchToProps = {
    showSignIn, hideSignIn
}

export default connect( mapStateToProps,mapDispatchToProps )(SignIn);