// imports dependencies
import { combineReducers } from 'redux';

// imports reducers
import { reducer as toastrReducer } from 'react-redux-toastr'
import Config from './Config'
import Pages from './Pages'
import Home from './Home'
import Products from './Products'
import Categories from './Categories'
import User from './User'

// creates root reducer
const rootReducer = combineReducers({
  Alerts: toastrReducer,
  Config,
  Pages,
  Home,
  Products,
  Categories,
  User,
})

// exports root reducer
export default rootReducer
