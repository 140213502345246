import React from "react";
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import Link from '../Link';
import logo from '../../Assets/Images/rocksel-logo-invert.png';

const Footer = ({ pages }) => {
  // TODO: procesar lista de pages segun su categoria

  const state = {
    menu1Items: ['Quiénes Somos', 'Generador aleatorio', 'Sobre la web app', ],
    menu2Items: ['Cómo funciona', 'Juego responsable', 'Política de envíos', ],
    menu3Items: ['Condiciones de uso', 'Política de privacidad', 'Cookies', ],
  };

  const { menu1Items, menu2Items, menu3Items } = state;

  const styles = {
    listTitle: {
      padding:'10px 1rem',
      fontWeight: 'bold'
    },
    listItem: {
      color: 'rgba(0, 0, 0, 0.6)',
    }
  }

  return(
    <>
      <footer className="page-footer">
        <Container>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} md={3}>
              <Link href={'/'}><img src={logo} alt={'Rocksel'}  style={{maxWidth: '18px', display:'block', marginTop: '10px', marginBottom: '1rem'}} /></Link>
              <Typography paragraph={true} component={'small'} ><small>Copyright 2020 &copy; Rocksel</small></Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Typography component={'h2'} style={styles.listTitle}>Rocksel</Typography>
              <List>
                {menu1Items.map((text, index) => (
                  <ListItem button key={text} dense={true} style={styles.listItem}>
                    <ListItemText primary={text} />
                  </ListItem>
                ))}
              </List>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Typography component={'h2'} style={styles.listTitle}>Ayuda</Typography>
              <List>
                {menu2Items.map((text, index) => (
                  <ListItem button key={text} dense={true} style={styles.listItem}>
                    <ListItemText primary={text} />
                  </ListItem>
                ))}
              </List>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Typography component={'h2'} style={styles.listTitle}>Legal</Typography>
              <List>
                {menu3Items.map((text, index) => (
                  <ListItem button key={text} dense={true} style={styles.listItem}>
                    <ListItemText primary={text} />
                  </ListItem>
                ))}
              </List>
            </Grid>
          </Grid>
        </Container>
      </footer>
    </>
  ); 
}

const mapStateToProps = ( state, Pages ) => {
  return {
    pages: Pages.items,
    isFetching: Pages.isFetching
  } 
}

export default connect(mapStateToProps)( Footer );