import * as API from '../API/API';


export const SHOW_SIGNUPWITH = 'SHOW_SIGNUPWITH';
export const showSignUpWith = () => {
    return {
        type: SHOW_SIGNUPWITH
    }
}

export const HIDE_SIGNUPWITH = 'HIDE_SIGNUPWITH';
export const hideSignUpWith = () => {
    return {
        type: HIDE_SIGNUPWITH
    }
}

export const TOGGLE_SIGNHUPWITH = 'TOGGLE_SIGNHUPWITH';
export const toggleSignUpWith = () => {
    return {
        type: TOGGLE_SIGNHUPWITH
    }
}

export const SHOW_SIGNUPEMAIL = 'SHOW_SIGNUPEMAIL';
export const showSignUpEmail = () => {
    return {
        type: SHOW_SIGNUPEMAIL
    }
}

export const HIDE_SIGNUPEMAIL = 'HIDE_SIGNUPEMAIL';
export const hideSingUpEmail = () => {
    return {
        type: HIDE_SIGNUPEMAIL
    }
}

export const TOGGLE_SIGNUPEMAIL = 'TOGGLE_SIGNUPEMAIL';
export const toggleSignUpEmail = () => {
    return {
        type: TOGGLE_SIGNUPEMAIL
    }
}

export const SHOW_SIGNIN = 'SHOW_SIGNIN';
export const showSignIn = () => {
    return {
        type: SHOW_SIGNIN
    }
}

export const HIDE_SIGNIN = 'HIDE_SIGNIN';
export const hideSignIn = () => {
    return {
        type: HIDE_SIGNIN
    }
}

export const TOGGLE_SIGNIN = 'TOGGLE_SIGNIN';
export const toggleSignIn = () => {
    return {
        type: TOGGLE_SIGNIN
    }
}

export const SHOW_PROFILE = 'SHOW_PROFILE';
export const showProfile = () => {
    return {
        type: SHOW_PROFILE
    }
}

export const HIDE_PROFILE = 'HIDE_PROFILE';
export const hideProfile = () => {
    return {
        type: HIDE_PROFILE
    }
}

export const TOGGLE_PROFILE = 'TOGGLE_PROFILE';
export const toggleProfile = () => {
    return {
        type: TOGGLE_PROFILE
    }
}

export const SET_USER_TOKEN = 'SET_USER_TOKEN';
export const setUserToken = (token) => {
    return {
        type: SET_USER_TOKEN,
        token: token
    }
}

export const RESET_USER_TOKEN = 'RESET_USER_TOKEN';
export const resetUserToken = () => {
    return {
        type: RESET_USER_TOKEN
    }
}

export const REQUEST_USER = 'REQUEST_USER';
export const requestUser = () => {
    return {
        type: REQUEST_USER,
        isFetching: true
    }
}

export const RECEIVE_USER = 'RECEIVE_USER';
export const receiveUser = currentUser => {
    return {
        type: RECEIVE_USER,
        currentUser: currentUser
    }
};

export const ERROR_RECEIVE_USER = 'ERROR_RECEIVE_USER';
export const errorReceiveUser = ( error ) => {
    return {
        type: ERROR_RECEIVE_USER,
        error: error,
    }
}

export const LOGOUT_USER = 'LOGOUT_USER';
export const logoutUser = () => {
    return {
        type: LOGOUT_USER,
        currentUser: null
    }
}

export const SIGNUP_USER = 'SIGNUP_USER';
export const signUpUser = (currentUser) => {
    return {
        type: SIGNUP_USER,
        currentUser: currentUser
    }
}

export function fetchUser(response){
    console.log('fetchUser enviando datos...')
    console.log(response)

    return ( dispatch, getState ) => {
        dispatch(requestUser())
        return API.loginRegister(response)
        .then( data => dispatch(receiveUser(data)) )
        .catch( error => dispatch(errorReceiveUser(error)) )
    }
}

export const REQUEST_DRAW = 'REQUEST_DRAW';
export const requestDraw = () => {
    return {
        type: REQUEST_DRAW,
        isFetching: true
    }
}

export const RECEIVE_DRAW = 'RECEIVE_DRAW';
export const receiveDraw = currentDraw => {
    return {
        type: RECEIVE_DRAW,
        currentDraw: currentDraw
    }
};

export const ERROR_RECEIVE_DRAW = 'ERROR_RECEIVE_DRAW';
export const errorReceiveDraw = ( error ) => {
    return {
        type: ERROR_RECEIVE_DRAW,
        error: error,
    }
}

export function fetchDraw(user, currentDraw){
    return (dispatch, getState) =>{
        dispatch(requestDraw())
        return API.jugar(user, currentDraw)
        .then( data => dispatch(receiveDraw(data)) )
        .catch( error => dispatch(errorReceiveDraw(error)) )
    }
}