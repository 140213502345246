import React from "react";

import styled from 'styled-components';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


const ProductSlider =  function(props){
    const sliderSettings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
    }

    return(
        <Slider {...sliderSettings}>
            { props.product.gallery.length && props.product.gallery.map( ( image, index ) => {
                return (<ImageContainer key={index}><ImageStyled src={image} /></ImageContainer>);
            } )}
        </Slider>
    );
}

const smWidth = '480px';
//const mdWidth = '1024px';

const ImageContainer = styled.div`
    background: #F4F4F4;
    object-fit: contain;
    position: relative;
    width: 100%;
    padding-top: 133%;
    overflow: hidden;
    @media (max-width: ${smWidth} ) {
        margin-top: 1rem;
    }
`;

const ImageStyled = styled.img`
    position: absolute;
    top: 0; left: 0; bottom: 0; right: 0;
    margin: auto;
    max-width: 100%;
    width: 100%;
`;

export default ProductSlider;