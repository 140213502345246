import React from "react";
import { connect } from 'react-redux';
import LocalMallIcon from '@material-ui/icons/LocalMall';
import ReactHtmlParser from 'react-html-parser';
import CTAVertical from '../CTAVertical';
import CTAHorizontal from '../CTAHorizontal';
import Banner from '../Banner';
import ProductList from '../ProductList';
import { showTabs } from '../../Actions/Config';

const HomeView = ({ home, products, dispatch }) => {

  dispatch( showTabs() );

  return (
    <>        
      <CTAVertical title={home.featured_title} product={products.find( product => product.id === home.featured_product )} />
      <ProductList 
        title={ ReactHtmlParser(home.featured_subtitle) } 
        products={ products.filter( product => product.featured ) }
        number={4}
        columns={4}
      />
        
      <Banner text={'En Rocksel puedes ganar productos TOP de forma divertida y más barato.'} icon={<LocalMallIcon color={'secondary'} />} button={['REGÍSTRATE','/registro']} />
      
      <CTAVertical title={home.electronica_title} product={products.find( product => product.id === home.electronica_product )} />
      <ProductList 
        title={home.electronica_subtitle}
        products={ products.filter( product => product.category.includes('electronica') ) }
        number={2}
        columns={2}
      />

      <CTAHorizontal title={home.moda_title} product={products.find( product => product.id === home.moda_product )} />
      <ProductList 
        title={home.moda_subtitle}
        products={ products.filter( product => product.category.includes('moda') ) }
        number={2}
        columns={2}
      />

      <CTAHorizontal title={home.deporte_title} product={products.find( product => product.id === home.deporte_product )} />
      <ProductList 
        title={home.deporte_subtitle}
        products={ products.filter( product => product.category.includes('deportes') ) }
        number={2}
        columns={2}
      />

      <CTAHorizontal title={home.hogar_title} product={products.find( product => product.id === home.hogar_product )} />
      <ProductList 
        title={home.hogar_subtitle}
        products={ products.filter( product => product.category.includes('hogar') ) }
        number={2}
        columns={2}
      />

    </>
  );
};

const mapStateToProps = ( { Home, Products }) => {
  return { 
    home: Home.items, 
    products: Products.items,
  }
}

export default connect(mapStateToProps)(HomeView);