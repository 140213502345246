import { REQUEST_PAGES, RECEIVE_PAGES, RECEIVE_CATEGORIES, ERROR_RECEIVE_PAGES } from '../Actions/Pages';

const initialState = {
  isFetching: false,
  items: [
    {
        "ID": 296,
        "title": "Política de envíos",
        "slug": "politica-de-envios",
        "content": "",
        "categories": [
            "ayuda"
        ]
    },
    {
        "ID": 294,
        "title": "Cómo funciona",
        "slug": "como-funciona",
        "content": "",
        "categories": [
            "ayuda",
            "menu"
        ]
    },
    {
        "ID": 291,
        "title": "Cookies",
        "slug": "cookies",
        "content": "",
        "categories": [
            "legal"
        ]
    },
    {
        "ID": 289,
        "title": "Política de privacidad",
        "slug": "politica-de-privacidad-2",
        "content": "",
        "categories": [
            "legal"
        ]
    },
    {
        "ID": 286,
        "title": "Sobre la web app",
        "slug": "sobre-la-web-app",
        "content": "",
        "categories": [
            "rocksel"
        ]
    },
    {
        "ID": 13,
        "title": "Juego responsable",
        "slug": "juego-responsable",
        "content": "",
        "categories": [
            "ayuda"
        ]
    },
    {
        "ID": 11,
        "title": "Condiciones de uso",
        "slug": "condiciones-de-uso",
        "content": "",
        "categories": [
            "legal"
        ]
    },
    {
        "ID": 8,
        "title": "Generador aleatorio",
        "slug": "generador-aleatorio",
        "content": "",
        "categories": [
            "rocksel"
        ]
    },
    {
        "ID": 6,
        "title": "Quienes somos",
        "slug": "quienes-somos",
        "content": "",
        "categories": [
            "rocksel"
        ]
    }
  ],
  lastUpdated: null,
  error: null,
};

const Pages = ( state = initialState, action ) => {
  switch (action.type) {

    case REQUEST_PAGES:
      return {
        ...state,
        isFetching: true,
      };
    case RECEIVE_PAGES:
      return {
        ...state,
        isFetching: false,
        items: action.items,
        lastUpdated: Date.now(),
      };
    case ERROR_RECEIVE_PAGES:
      return {
        ...state,
        isFetching: false,
        error: action.error,
      };

    default:
      return state
  }
}

export default Pages