import React from 'react';
import './App.css';
import { ThemeProvider } from '@material-ui/core/styles';
import theme from './theme';
import Header from './Components/Layout/Header';
import Container from './Components/Layout/Container';
import Footer from './Components/Layout/Footer';
import WhoItWorks from './Components/Layout/WhoItWorks';
import SignUpWith from './Components/SignUpWith';
import Profile from './Components/Profile';;

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Header />
      <Container />
      <WhoItWorks />
      <SignUpWith />
      <Profile/>
      <Footer />
    </ThemeProvider>
  );
}

export default App;
