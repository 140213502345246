import React from "react";
import { connect } from 'react-redux';
import ProductList from '../ProductList';
import { showTabs } from '../../Actions/Config';

const CategoryView = ({ category, products, dispatch, tabs }) => {

  if( ! tabs )
    dispatch( showTabs() );

  if( !category )
    return(<></>);

  return (
    <>
      <ProductList 
        title={category.name} 
        products={products}
        number={30}
        columns={4}
      />
    </>
  );
};


const mapStateToProps = ( { Categories, Products, Config }, ownProps ) => {
  const { category_slug } =  ownProps.match.params;
  return {
    tabs: Config.showTabs,
    category: Categories.items.find( category => category.slug === category_slug ),
    products: Products.items.filter( product => product.category.includes( category_slug ) ),
  }
}

export default connect(mapStateToProps)(CategoryView);