import React from "react";

import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import FormControl from '@material-ui/core/FormControl';
import Slide from '@material-ui/core/Slide';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) =>({
    centrado:{
        textAlign: 'center'
    },
    subtitulo:{
        color: 'rgba(0, 0, 0, 0.6)',
        fontWeight: '500'
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        marginBottom: '1rem'
    },
    formControl: {
        marginTop: theme.spacing(2),
        minWidth: 120,
    },
    formControlLabel: {
        marginTop: theme.spacing(1),
        paddingTop: theme.spacing(1),
    },
    dorado:{
        color: '#d4c8b7',
        '&:hover': {
            color: theme.palette.primary.main
        }
    }
}));

const ProductPaymentDialog =  function(props){
    const classes = useStyles();
    let numerocvc;

    const onChange = ( event ) => {
        numerocvc = event.target.value;
    }

    const onSubmit = () =>{
        props.onSubmit(numerocvc)
    }

    return(
        <Dialog
            open={props.open}
            TransitionComponent={Transition}
            keepMounted
            onClose={props.onClose}
            onSubmit={props.onSubmit}
            aria-describedby="alert-dialog-slide-description"
        >
            <form className={classes.form} noValidate>
                <DialogContent className={classes.centrado}>
                    <DialogContentText id="alert-dialog-slide-description">
                        <CreditCardIcon color="primary"/>
                        <Typography variant="subtitle2" color="primary" component="span" gutterBottom> Realizando pago </Typography>
                        <Typography variant="caption" display="block" className={classes.subtitulo} gutterBottom> Para confirmar el pago introduzca el CVC... </Typography>
                        
                            <FormControl className={classes.formControl}>
                                <TextField
                                    id="outlined-helperText"
                                    label="CVC"
                                    variant="outlined"
                                    onChange={onChange}
                                />
                            </FormControl>
                        
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={props.onClose} className={classes.dorado}>
                        CANCELAR PAGO
                    </Button>
                    <Button onClick={onSubmit} className={classes.dorado}>
                        OK
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    );
}

export default ProductPaymentDialog;