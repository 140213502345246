// React Redux
import React from "react";
import { connect } from 'react-redux';
import styled from 'styled-components';

// Material UI
import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

// Rocksel
import ProfileEditPayment from './ProfileEditPayment';

const useStyles = makeStyles(() =>({
    root: {
        flexGrow: 1,
    },
    box:{
        border: 'solid 1px rgba(0, 0, 0, 0.12)',
        borderRadius: '4px',
        marginBottom: '1rem'
    },
    mb0:{
        marginBottom: '0'
    },
    circlebg:{
        backgroundColor: '#d4c8b7'
    },
    separar: {
        margin: '1rem 0'
    },
    btnredondo:{
        borderRadius: '25px',
        padding: '.5rem 2rem',
        margin: '1rem 0 1rem 0'
    }
}));

const PaymentBox = ({ user }) =>{

    const classes = useStyles();

    return(
        <>
        <Box className={classes.box} p={2}>
            {user && user.payment && user.payment.cardtype!=='' && user.payment.lastnumber!=='' && user.payment.cardexp!=='' ?
            [<Grid container wrap="nowrap" spacing={2}>
                <Grid item>
                    <Avatar className={classes.circlebg}>
                        <CreditCardIcon color="primary"/>
                    </Avatar>
                </Grid>
                <Grid item xs>
                    <Typography gutterBottom variant="subtitle1" className={classes.mb0}> {user.payment.cardtype} ••••{user.payment.lastnumber} </Typography>
                    <Typography variant="body2" color="textSecondary"> Fecha de caducidad: {user.payment.cardexp} </Typography>
                </Grid>
            </Grid>,
            <Typography variant="body1" color="textSecondary" className={classes.separar} gutterBottom>Configura tu método de pago.</Typography>,
            <ContenedorBotones>
                <Button className={classes.btnredondo}>BORRAR</Button>
                <ProfileEditPayment buttonText={'Editar'}/>
            </ContenedorBotones>]
            :
            [<Typography variant="body1" color="textSecondary" className={classes.separar} gutterBottom>No se ha añadido tarjeta. Configura tu método de pago.</Typography>,
            <ContenedorBotones>
                <ProfileEditPayment buttonText={'Añadir'} dialogTitle={'Añadir método de pago'}/>
            </ContenedorBotones>]
            }
        </Box>
        </>
    );
}

const ContenedorBotones = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
`;

const mapStateToProps = ({ User }) => {
    return {
        user: User.currentUser
    }
}

export default connect(mapStateToProps)(PaymentBox);