import React from "react";

import styled from 'styled-components';

import Dialog from '@material-ui/core/Dialog';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Slide from '@material-ui/core/Slide';
import Typography from '@material-ui/core/Typography';
import SettingsIcon from '@material-ui/icons/Settings';
import HelpIcon from '@material-ui/icons/Help';
import Box from '@material-ui/core/Box';

import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import Link from './Link';
import logo from '../Assets/Images/logo-rocksel.png';
import Footer from './Layout/Footer';
import ProfilePaymentBox from './ProfilePaymentBox';
import ProfileAddressBox from './ProfileAddressBox';
import ProfileSettingsBox from './ProfileSettingsBox';
import ProfilePaymentTable from './ProfilePaymentTable';
import ProfileShippingTable from './ProfileShippingTable';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const ProfileDialog =  function(props){
    const [value, setValue] = React.useState(0);

    const handleChangeTab = (event, newValue) => {
        setValue(newValue);
    };

    const TabPanel = (props) => {
        const { children, value, index, ...other } = props;
    
        return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
            <Box p={3}>
                <Typography component="span">{children}</Typography>
            </Box>
            )}
        </div>
        );
    }

    const a11yProps = (index) => {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    return(
        <Dialog fullScreen onClose={props.onClose} aria-labelledby="Perfil" open={props.open} TransitionComponent={Transition}>

            <AppBarStyled color={'primary'} elevation={0} >
                <Toolbar className="topbar">
                    <div style={{flexBasis:'33.33%', display: 'flex', alignItems: 'center'}}>
                        <IconButton edge="start" color="inherit" onClick={props.onClose} aria-label="close">
                            <CloseIcon />
                        </IconButton>
                        
                        <Box component={'div'} display={{xs: 'none', md:'block' }}>
                            <Typography variant="body1">Perfil</Typography>
                        </Box>
                    </div>
                    
                    <Box component={'div'} className={'logo'} style={{flexBasis:'33.33%', textAlign: 'center'}}>
                        <Link href={'/'}><img src={logo} alt={'Rocksel'}  css={{maxWidth: '100%', display:'block'}} /></Link>  
                    </Box>

                    <Box component={'div'} /*display={{xs: 'none', md:'block' }}*/ style={{flexBasis:'33.33%', textAlign: 'right'}}>
                        <IconButton autoFocus color="inherit" onClick={props.onClose}>
                            <HelpIcon />
                        </IconButton>
                        <IconButton autoFocus color="inherit" onClick={props.onClose}>
                            <SettingsIcon/>
                        </IconButton>
                    </Box>
                </Toolbar>
            </AppBarStyled>

            <BackgroundStyled>
                <ContainerStyled>
                    <Grid container>
                        <Grid item xs={12} md={12}>
                            <Tabs
                                value={value}
                                onChange={handleChangeTab}
                                indicatorColor="primary"
                                textColor="primary"
                                centered
                            >
                                <Tab label="CONFIGURACIÓN" {...a11yProps(0)} />
                                <Tab label="PAGOS" {...a11yProps(1)} />
                                <Tab label="ENVÍOS" {...a11yProps(2)} />
                            </Tabs>

                            <TabPanel value={value} index={0}>
                                <Grid container spacing={1}>
                                    <Grid item xs={12} sm={6}>
                                        <ProfilePaymentBox/>
                                        <ProfileAddressBox/>
                                    </Grid>

                                    <Grid item xs={12} sm={6}>
                                        <ProfileSettingsBox/>
                                    </Grid>
                                </Grid>
                            </TabPanel>
                            <TabPanel value={value} index={1}>
                                <ProfilePaymentTable/>
                            </TabPanel>
                            <TabPanel value={value} index={2}>
                                <ProfileShippingTable/>
                            </TabPanel>              
                        </Grid>
                    </Grid>
                </ContainerStyled>
            </BackgroundStyled>

            <Footer/>

        </Dialog>
    );
}

const BackgroundStyled = styled.div`
    background: #F4F4F4;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 3rem;
`;

const ContainerStyled = styled(Container)`
    min-height: 500px;
    max-width: 1024px;
    background: #fff;
`;


const AppBarStyled = styled(AppBar)`
    position: relative;
`;

export default ProfileDialog;