import React from "react";

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import styled from 'styled-components';
import theme from '../theme';
import Link from './Link';

import TextField from '@material-ui/core/TextField';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles((theme) => ({
    form: {
        display: 'flex',
        flexDirection: 'column',
        margin: 'auto',
        width: 'fit-content',
        marginBottom: '1rem'
    },
    formControl: {
        marginTop: theme.spacing(2),
        minWidth: 120,
    },
    formControlLabel: {
        marginTop: theme.spacing(1),
    },
}));

const SignInDialog =  function(props){
    const classes = useStyles();

    const [values, setValues] = React.useState({
        password: '',
        showPassword: false,
    });

    const handleChangePassword = (prop) => (event) => {
        setValues({ ...values, [prop]: event.target.value });
    };
    
    const handleClickShowPassword = () => {
        setValues({ ...values, showPassword: !values.showPassword });
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    return (
        <Dialog fullWidth={true} maxWidth={'sm'} onClose={props.onClose} aria-labelledby="simple-dialog-title"  open={props.open}>
            <IconButton aria-label="close" style={styles.closeButton} onClick={props.onClose}>
                <CloseIcon />
            </IconButton>

            <DialogContent>
                <DialogContentText id="alert-dialog-description" style={styles.contenedorLogin}>
                    <Typography variant="h2" component="h1" color="primary" style={styles.okaeri} >Hola de nuevo</Typography>

                    <form className={classes.form} noValidate>
                        <FormControl className={classes.formControl}>
                            <TextField
                                id="outlined-helperText"
                                label="Email"
                                defaultValue=""
                                variant="outlined"
                            />
                            </FormControl>
                            <FormControl className={classes.formControl} variant="outlined">
                                <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
                                <OutlinedInput
                                    id="outlined-adornment-password"
                                    label="Password"
                                    type={values.showPassword ? 'text' : 'password'}
                                    value={values.password}
                                    onChange={handleChangePassword('password')}
                                    endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword}
                                        onMouseDown={handleMouseDownPassword}
                                        edge="end"
                                        >
                                        {values.showPassword ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                    </InputAdornment>
                                    }
                                />
                            </FormControl>
                    </form>

                    <StyledButton onClick={() => { console.log('Iniciar sesión...') }}>{'INICIA SESIÓN'}</StyledButton>
                    <Typography variant="body1">Al iniciar sesión aceptas nuestros <Link>Términos y condiciones</Link> y la <Link>Política de privacidad</Link></Typography>
                </DialogContentText>
            </DialogContent>
        </Dialog>
    );
}

const styles = {
    closeButton: {
        position: 'absolute',
        top: '0.5rem',
        left: '0.5rem',
        color: '#9e9e9e',
    },
    contenedorLogin:{
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: 'column',
        height: '100%',
        width: '65%',
        margin: '2rem auto 2rem auto',
        justifyContent: 'center'
    },
    regLink:{
        color: 'white',
        textDecoration: 'none'
    },
    okaeri:{
        fontFamily: 'Butler',
        fontSize: '4rem',
        textTransform: 'uppercase'
    }
};

const StyledButton = styled(Button)`
    background: ${theme.palette.primary.main};
    color: ${theme.palette.primary.contrastText};
    border-radius: 25px;
    padding: .5rem 2rem;
    margin: 1rem 0 1rem 0;
    pointer-events: initial;

    &:hover{
        background: ${theme.palette.ternary.main};
        color: ${theme.palette.ternary.contrastText};
    }
`;

export default SignInDialog;