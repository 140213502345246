import React, { Component } from "react";
import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr'
import styled from 'styled-components';

// Estructura base del return
import AppBar from '@material-ui/core/AppBar';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import CloseIcon from '@material-ui/icons/Close';
import Container from '@material-ui/core/Container';
import Dialog from '@material-ui/core/Dialog';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Slide from '@material-ui/core/Slide';

// Estilos y recursos del dialog
import Link from './Link';
import logo from '../Assets/Images/logo-rocksel.png';
import Footer from './Layout/Footer';

import ProfileEditAddress from './ProfileEditAddress';

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

class ProductShippingDialog extends Component {
    constructor(props){
        super(props);
        
        this.state = {
			open: false,
		}
		
	}

	// Control del Modal Dialog
	handleClickOpen = () => {
        this.setState({open: true});
	};

	handleClose = () => {
		this.setState({open: false});
	};

	render(){
		return (
			<div>
                <Button className="shipping-button" onClick={this.handleClickOpen}>
                    <LocalShippingIcon style={{marginRight:'0.5rem'}}/>Recibelo
                </Button>

                <Dialog fullScreen onClose={this.handleClose} aria-labelledby="Perfil" open={this.state.open} TransitionComponent={Transition}>

                    <AppBarStyled color={'primary'} elevation={0} >
                        <Toolbar className="topbar">
                            <div style={{flexBasis:'33.33%', display: 'flex', alignItems: 'center'}}>
                                <IconButton edge="start" color="inherit" onClick={this.handleClose} aria-label="close">
                                    <CloseIcon />
                                </IconButton>
                                
                                <Box component={'div'} display={{xs: 'none', md:'block' }}>
                                    <Typography variant="body1">Recíbelo</Typography>
                                </Box>
                            </div>
                            
                            <Box component={'div'} className={'logo'} style={{flexBasis:'33.33%', textAlign: 'center'}}>
                                <Link href={'/'}><img src={logo} alt={'Rocksel'}  css={{maxWidth: '100%', display:'block'}} /></Link>  
                            </Box>

                            <Box component={'div'} /*display={{xs: 'none', md:'block' }}*/ style={{flexBasis:'33.33%', textAlign: 'right'}}>
                                
                            </Box>
                        </Toolbar>
                    </AppBarStyled>

                    <BackgroundStyled>
                        <ContainerStyled>
                            <Grid container>
                                <Grid item xs={12} md={12}>
                                    <Typography  variant="h5" component="h1" style={{textAlign:'center', margin: '1rem'}}>Confirma tu envío</Typography>
                                </Grid>

                                <Grid item xs={12} sm={6}>
                                    <Grid container wrap="nowrap" spacing={2}>
                                        <Grid item>
                                            <img src={this.props.product.imageMobile} alt={this.props.product.name}  style={{width: '80px', height: '80px', display:'block'}} />
                                        </Grid>
                                        <Grid item xs >{/* style={{borderBottom: '1px solid rgba(0,0,0,0.12)'}} */}
                                            <Typography variant="subtitle2" gutterBottom dangerouslySetInnerHTML={{__html:this.props.product.name}} />
                                            <Typography 
                                                variant="caption" 
                                                display="block" 
                                                style={{
                                                    color: 'rgba(0, 0, 0, 0.6)', 
                                                    fontWeight: '500',
                                                    borderBottom: '1px solid rgba(0,0,0,0.12)',
                                                    padding: '0 0 1rem 0',
                                                    marginRight: '2rem'
                                                }} 
                                                gutterBottom dangerouslySetInnerHTML={{__html:this.props.product.excerpt}}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid item xs={12} sm={6}>
                                    <Box p={2}>
                                        {this.props.user && this.props.user.shipping && this.props.user.name && this.props.user.name!=='' && this.props.user.shipping.address!=='' && this.props.user.shipping.postcode!=='' && this.props.user.shipping.city!=='' && this.props.user.shipping.state!=='' && this.props.user.shipping.phone!==''?
                                        [<Grid container wrap="nowrap" spacing={2}>
                                            <Grid item>
                                                <Avatar className="circlebg">
                                                    <LocalShippingIcon color="primary"/>
                                                </Avatar>
                                            </Grid>
                                            <Grid item xs className="shipping-address">
                                                <Typography gutterBottom variant="subtitle1" className="mb0"> {this.props.user.name} </Typography>
                                                <Typography variant="body2" color="textSecondary">
                                                    {this.props.user.shipping.address}<br/>
                                                    {this.props.user.shipping.postcode} {this.props.user.shipping.city} ({this.props.user.shipping.state}) <br/>
                                                    Tlfno. {this.props.user.shipping.phone}
                                                </Typography>
                                            </Grid>
                                            <Grid item>
                                                <ProfileEditAddress buttonText={'Editar'} dialogTitle={'Editar datos de envío'}/> 
                                            </Grid>
                                        </Grid>]
                                        :
                                        [<Typography variant="body1" color="textSecondary" className="separar" gutterBottom>No se ha añadido dirección. Configura tu dirección de envío.</Typography>,
                                        <ContenedorBotones>
                                            <ProfileEditAddress buttonText={'Añadir'} dialogTitle={'Añadir datos de envío'}/>
                                        </ContenedorBotones>]
                                        }
                                    </Box>
                                    <Typography variant="body1" color="textSecondary" gutterBottom style={{padding: '16px'}}>Asegúrate de que los datos de envío sean correctos.</Typography>
                                </Grid>

                                <Grid item xs={12} md={12} style={{textAlign: 'center', marginTop: '1rem'}}>
                                    <Button className="btnBlack">Confirmar envío.</Button>
                                </Grid>
                            </Grid>
                        </ContainerStyled>

                        <ContainerStyledMsg>
                            <Grid container>
                                <Grid item xs={6} md={6} style={{textAlign: 'left'}}>
                                    Envío preparado
                                </Grid>
                                <Grid item xs={6} md={6} style={{textAlign: 'right'}}>
                                    <ButtonStyled >Ver estado</ButtonStyled>
                                </Grid>
                            </Grid>
                        </ContainerStyledMsg>
                    </BackgroundStyled>

                    <Footer/>

                </Dialog>
			</div>
		);
	}

}

const BackgroundStyled = styled.div`
    background: #F4F4F4;
    /*display: flex;
    justify-content: space-between;
    align-items: center;*/
    padding-bottom: 3rem;
`;

const ContainerStyled = styled(Container)`
    min-height: 500px;
    max-width: 1024px;
    background: #fff;
`;

const ContainerStyledMsg = styled(Container)`
    max-width: 1024px;
    color: #fff;
    background-color: rgba(0, 0, 0, 0.87);
    line-height: 48px;
    border-radius: 4px;
    margin-top: 1rem;
`;

const AppBarStyled = styled(AppBar)`
    position: relative;
`;

const ButtonStyled = styled(Button)`
    color: #d4c8b7 !important;
`;

const ContenedorBotones = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
`;

const mapStateToProps = ( { User } ) => {
    return {
        user: User.currentUser
    }
}

const mapDispatchToProps = {}

export default  connect( mapStateToProps,mapDispatchToProps )(ProductShippingDialog);
