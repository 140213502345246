import React from "react";
import { connect } from 'react-redux';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import TabLink from './TabLink';

const TabsNavigation = ( { currentTab, categories, show } ) => {

  if( !show ){
    return (<></>);
  }

  const a11yProps = ( category ) => {
    return {
      label: category.name,
      id: `${category.slug}`,
      value: `${category.slug}`,
      href: `/categoria/${category.slug}/`,
      'data-value': `${category.slug}`, 
      'aria-controls': `${category.slug}`,
      component: TabLink,
    };
  }

  const DestacadosProps = {
    label: 'Destacados',
    id: 'destacados',
    value: 'destacados',
    href: '/',
    'data-value': 'destacados', 
    'aria-controls': 'destacados',
    component: TabLink,
  };

  return(
    <>
      <AppBar position={'static'} color={'secondary'} elevation={0}>
        <Tabs value={currentTab} aria-label={'Categorias'} textColor={'primary'} indicatorColor={'primary'} centered={true}>
          <Tab {...DestacadosProps} />
          { categories.map( category => {
            return ( <Tab key={category.id} {...a11yProps(category)} />);
          })}
        </Tabs>
      </AppBar>
    </>
  );
}

const mapStateToProps = ( { Config, Categories} ) => {
  return {
    currentTab: Config.currentTab,
    categories: Categories.items,
    show: Config.showTabs,
  };
}

export default connect(mapStateToProps)(TabsNavigation);