import React from "react";
import { connect } from 'react-redux';
import styled from 'styled-components';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import {Link} from 'react-router-dom';

const CTAVertical = ( props ) => {
  const { product, title } = props;
  return (
    <ContainerLink to={ product ? `/producto/${product.slug}/` : '' }>
      <BackgroundStyled>
        <ContainerStyled>
          <Title variant={"h2"} component={"h2"} align={'center'}>{title}</Title>
          { product && (
            <>
              <ProductName component={"p"} align={'center'}>{product.name}</ProductName>
              <ProductPrice component={"p"} align={'center'}>{'Desde '}{product.price}{'€ la casilla'}</ProductPrice>
              <StyledImg src={product.imageDesktop} />
            </>
          )}
        </ContainerStyled>
      </BackgroundStyled>
    </ContainerLink>
  );
}

const smWidth = '480px';
//const mdWidth = '1024px';

const BackgroundStyled = styled.div`
  background: #fafafa;
`;

const StyledImg = styled.img`
  justify-self: flex-end;
  max-width: 90%;
`;

const ContainerLink = styled(Link)`
  font-style: inherit;
  text-decoration: none;
`;

const Title = styled(Typography)`
  font-family: 'Butler', serif;
  margin: 3rem 0 2rem;
  font-size: 4rem;
  font-weight: 400;
  @media (max-width: ${smWidth} ) {
    font-size: 3rem;
  }
`;

const ProductName = styled(Typography)`
  font-size: 1.5rem;
  font-weight: 500;
  margin-bottom: .7rem;
`;

const ProductPrice = styled(Typography)`
  color: rgba(0, 0, 0, 0.6);
  margin-bottom: 2rem;
`;

const ContainerStyled = styled(Container)`
  display: flex !important;
  flex-direction: column;
  align-items: center;
  padding: 50px 0 0;
`;

const mapStateToProps = state => state;

export default connect(mapStateToProps)(CTAVertical);