import React, { Component } from "react";
import { connect } from 'react-redux';

import { showProductPayment, hideProductPayment } from '../Actions/Products';
import ProductPaymentDialog from './ProductPaymentDialog';

class ProductPayment extends Component{
    constructor(props){
        super(props);
        this.handleClickOpen = this.handleClickOpen.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleClickOpen(event){
        event.preventDefault();
        this.props.showProductPayment();
    }

    handleClose(){
        this.props.hideProductPayment();
        console.log('Pago cancelado');
    }

    handleSubmit(numerocvc){
        this.props.hideProductPayment();
        console.log('Realizar pago: '+numerocvc);
        this.props.handlePayment( numerocvc );
    }

    render(){
        return(
            <>
                <ProductPaymentDialog 
                    open={this.props.productPaymentOpen} 
                    onClose={this.handleClose}
                    onSubmit={this.handleSubmit}
                />
            </>
        )
    }
}

const mapStateToProps = ({ Products }) => {
    return { 
        productPaymentOpen: Products.productPaymentOpen
    }
}

const mapDispatchToProps = {
    showProductPayment, hideProductPayment
}

export default connect( mapStateToProps,mapDispatchToProps )(ProductPayment);