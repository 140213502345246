import React from "react";
import { connect } from 'react-redux';
import styled from 'styled-components';
import Typography from '@material-ui/core/Typography';
import {Link} from 'react-router-dom';

const ProductCard = ( { product, actions } ) => {
  return(
    <ArticleStyled>
      <Container>
        <Link to={`/producto/${product.slug}/`}>
          <ImageContainer>
            <ImageStyled src={product.gallery[0]} />
          </ImageContainer>
        </Link>
        <ProductName component={'h2'}>
          <LinkStyled to={`/producto/${product.slug}/`} dangerouslySetInnerHTML={{__html:product.name}} />
        </ProductName>
        <ProductPrice>
          <LinkStyled to={`/producto/${product.slug}/`}>
            Por {product.price}€
          </LinkStyled>
        </ProductPrice>
      </Container>
    </ArticleStyled>
  ); 
}

//const smWidth = '480px';
//const mdWidth = '1024px';

const Container = styled.article`
  display: flex;
  flex-direction: column;
  cursor: pointer;
`;

const ImageContainer = styled.div`
  background: #F4F4F4;
  object-fit: contain;
  position: relative;
  width: 100%;
  padding-top: 133%;
  overflow: hidden;
`;
const ImageStyled = styled.img`
  position: absolute;
  top: 0; left: 0; bottom: 0; right: 0;
  margin: auto;
  max-width: 100%;
`;

const ProductName = styled(Typography)`
  padding-top: 2.1rem;
  font-size: 1.1rem;
`;

const ProductPrice = styled(Typography)`
  font-size: 1.25rem !important;
  font-weight: 500 !important;
`;


const ArticleStyled = styled.article`
  transition: transform .1s ease-in-out;
  &:hover{
    transform: scale(1.05);
    z-index: 100;
  }
`;

const LinkStyled = styled(Link)`
  font-size: inherit;
  color: inherit;
  text-decoration: inherit;
`;

const mapStateToProps = ( state ) => { return state; }

export default connect(mapStateToProps)(ProductCard);