export const SET_CURRENT_TAB = 'SET_CURRENT_TAB';
export const setCurrentTab = ( tab ) => {
  return {
    type: SET_CURRENT_TAB,
    tab: tab,
  }
}

export const OPEN_MENU = 'OPEN_MENU';
export const openMenu = () => {
  return {
    type: OPEN_MENU,
  }
}

export const CLOSE_MENU = 'CLOSE_MENU';
export const closeMenu = () => {
  return {
    type: CLOSE_MENU,
  }
}

export const TOGGLE_MENU = 'TOGGLE_MENU';
export const toggleMenu = () => {
  return {
    type: TOGGLE_MENU,
  }
}

export const SHOW_TABS = 'SHOW_TABS';
export const showTabs = () => {
  return {
    type: SHOW_TABS,
  }
}

export const HIDE_TABS = 'HIDE_TABS';
export const hideTabs = () => {
  return {
    type: HIDE_TABS,
  }
}

export const TOGGLE_TABS = 'TOGGLE_TABS';
export const toggleTabs = () => {
  return {
    type: TOGGLE_TABS,
  }
}

export const SHOW_WELCOME = 'SHOW_WELCOME';
export const showWelcome = () => {
  return {
    type: SHOW_WELCOME,
  }
}

export const HIDE_WELCOME = 'HIDE_WELCOME';
export const hideWelcome = () => {
  return {
    type: HIDE_WELCOME,
  }
}

export const TOGGLE_WELCOME = 'TOGGLE_TABS';
export const toggleWelcome = () => {
  return {
    type: TOGGLE_WELCOME,
  }
}

export const SHOW_WHOITWORKS_BUTTON = 'SHOW_WHOITWORKS_BUTTON';
export const showWhoItWorksButton = () => {
  return {
    type: SHOW_WHOITWORKS_BUTTON,
  }
}

export const HIDE_WHOITWORKS_BUTTON = 'HIDE_WHOITWORKS_BUTTON';
export const hideWhoItWorksButton = () => {
  return {
    type: HIDE_WHOITWORKS_BUTTON,
  }
}

export const TOGGLE_WHOITWORKS_BUTTON = 'TOGGLE_WHOITWORKS_BUTTON';
export const toggleWhoItWorksButton = () => {
  return {
    type: TOGGLE_WHOITWORKS_BUTTON,
  }
}
