import React from "react";
import { connect } from 'react-redux';
import styled from 'styled-components';
import Typography from '@material-ui/core/Typography';

import Button from '@material-ui/core/Button';
import { showSignUpWith } from '../Actions/User';
import { showWhoItWorksButton } from '../Actions/Config';

const Banner = ( { dispatch, text, icon, user } ) => {
  const loginButton = () => {
    if( user) return (
      <ButtonStyled onClick={ () => dispatch( showWhoItWorksButton() ) }>Como funciona</ButtonStyled>
    );   

    return (<ButtonStyled onClick={ () => dispatch( showSignUpWith() ) }>Accede</ButtonStyled>);
  }

  return(
    <>
      <BackgroundStyled>
        <Left>
          <IconStyled>{icon}</IconStyled>
          <TextStyled>{text}</TextStyled>
        </Left>
        <Right>
          {loginButton()}
        </Right>
      </BackgroundStyled>
    </>
  ); 
}

const smWidth = '480px';
//const mdWidth = '1024px';

const ButtonStyled = styled(Button)`
    font-size: 1.05rem;
    color: #d4c8b7 !important;
`;

const BackgroundStyled = styled.div`
  background: #212121;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
`;

const TextStyled = styled(Typography)`
  color: white;
  @media (max-width: ${smWidth} ) {
    
  }
`;

const Left =styled.div`
  display: flex;
  align-items: center;
`;
const Right =styled.div`
  display: flex;
  align-items: center;
`;

const IconStyled = styled.div`
  display:inline-block;
  width: 40px;
  height: 40px;
  min-width: 40px;
  min-height: 40px;
  background: #756a5b;
  border-radius: 50%;
  margin-right: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const mapStateToProps = ({ state, User }) => {
  return { 
    state: state,
    user: User.currentUser
  }
}
export default connect(mapStateToProps)(Banner);