import React, { Component, useState } from "react";
import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr'
import styled from 'styled-components';

// Estructura base del return
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import CloseIcon from '@material-ui/icons/Close';
import Container from '@material-ui/core/Container';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Slide from '@material-ui/core/Slide';



// Contenedor has perdido
import '../Assets/CSS/ProductDrawLose.css';

// Contenedor has ganado
import '../Assets/CSS/ProductDrawWin.css';
import globitos from '../Assets/Images/win.png';

// Contenedor Jugar
import '../Assets/CSS/ProductDrawPlay.css';
import ProductPayment from './ProductPayment';

// Contenedor Bettings
import '../Assets/CSS/ProductBetting.css';

// Estilos y recursos del dialog
import Link from './Link';
import logo from '../Assets/Images/logo-rocksel.png';
import Footer from './Layout/Footer';


import ProductShippingDialog from './ProductShippingDialog'

// Actions
import { showProductShipping, showProductPayment } from '../Actions/Products';
import { fetchDraw, showSignUpWith } from '../Actions/User';

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});


const Casilla = ( { num, onCheckboxChange } ) => {
    const classes='';
    const [ isSelected, setIsSelected ] = useState( false );

    const onChange = ( event ) => {
        // Cambiar clase selected de la casilla
        setIsSelected( ! isSelected );
        // Pasar datos al componente principal
        onCheckboxChange(event.target.checked, event.target.name, event.target.value);
    }

    const labelClasses = [ classes.label ];
    if( isSelected )
        labelClasses.push( 'selected' )

    return (
        <li>
            <label className={labelClasses.join(' ')}>
            <input type={'checkbox'} name={'selected[]'} value={num} className="check" onChange={onChange} />
            {num}
            </label>
        </li>
    )
}

class ProductPlayDialog extends Component {
    constructor(props){
		super(props);

        this.handleCheckboxChange = this.handleCheckboxChange.bind(this);

        this.state = {
			open: false,
            casillas: undefined,
            contador: 0,
            sorteo: undefined,
			cvc: undefined,
		}
		
	}

	// Control del Modal Dialog
	handleClickOpen = () => {
		// COMPROBAR SI EL USUARIO ESTÁ LOGEADO PARA MOSTRAR O NO EL DIALOG
        if(this.props.user){
            this.setState({open: true});
            console.log('Abrir modal dialog de juego');
            console.log(this.props.product);
        }else{
            // Si no está logeado abre modal para acceder
            this.props.showSignUpWith();
        }
	};

	handleClose = () => {
		this.setState({open: false});
        // IMPORTANTE! RESETEAR CONTADORES DE CASILLAS, SI SE MARCAN Y SE CIERRA AL VOLVER A ABRIR NO LO RENDERIZA COMO ESTABA Y APARECE BOTON JUGAR!
        this.resetCheckboxes();
	};

	// Control de casillas
    handleFormSubmit = (event) =>{
        event.preventDefault();
        console.log('submit');
        //this.props.fetchDraw('usuario', 'datosjuego');

        Object.keys(this.state.casillas)
            .filter(casilla => this.state.casillas[casilla])
            .forEach(casilla => {
                console.log(casilla, "is selected.");
        });

        // Comprobar si el usuario está logeado (! para debug local)
        if(this.props.user){
            // Si está logeado pregunta CVC
            this.props.showProductPayment();
        }else{
            // Si no está logeado abre modal para acceder
            this.props.showSignUpWith();
        }
	}
	
    precioCasillas(){
        const { contador } = this.state

        if(contador>0) return ([
            <Typography variant="h6" component="h1" color="textPrimary">{contador * this.props.product.price} €</Typography>,
            <Typography variant="body2" color="textSecondary">Por {contador} {contador===1 ? 'casilla' : 'casillas'}</Typography>
        ]);

        // Si no hay casillas seleccionadas mostrar botón Elegir
        return ([
            <Typography variant="h6" component="h1" color="textPrimary">{this.props.product.price} €</Typography>,
            <Typography variant="body2" color="textSecondary">Precio por casilla</Typography>
        ]);
    }

    resetCheckboxes(){
        const range = Array.from({length: (this.props.product.cells)}, (v, k) => k + 1);

        this.setState({
            casillas: range.reduce(
                (options, option) => ({
                    ...options,
                    [option]: false
                }),
                {}
            ),
            contador: 0,
        });
    }

    handleCheckboxChange = (checked, name, value) =>{
        const { contador } = this.state

        this.setState(prevState => ({
            casillas: {
                ...prevState.casillas,
                [value]: checked
            }
        }));

        if( checked ){
            this.setState({contador: contador+1});
        }else{
            this.setState({contador: contador-1});
        }
    }

    // Recibir CVC para el pago
    handlePayment = (numerocvc) =>{
        this.setState({ cvc: numerocvc }); // LO RECIBE PERO NO ME GUARDA EL CVC!!!!

        console.log('CVC recibido: '+numerocvc);
        
        // Si CVC<>0 enviar y realizar sorteo
        console.log('CVC en state: '+this.state.cvc);

        console.log('Procesar CVC y Jugar');
        if( numerocvc != undefined && numerocvc != 0 && numerocvc != '' ){
            // Si el pago es correcto realizar sorteo
            
            //console.log('Fetch Draw');

            this.setState({ sorteo: 2 })

            setTimeout( ()=>{this.setState({ sorteo: 1 })}, 3000);

            //this.props.fetchDraw(this.props.user, 'datosjuego');
            //this.handleDraw();

            // Reset CVC
            //this.setState({ cvc: undefined });
        }
    }

    // Botones Elegir o Jugar según si hay alguna casilla marcada
    botonesElegirJugar(){
        const { contador } = this.state

        // Si hay casillas seleccionadas mostrar botón Jugar
        if(contador>0) return (
            <Button type="submit" className="playbutton"> 
                <CheckCircleOutlineIcon style={{marginRight:'0.5rem'}}/> Jugar
            </Button>)
		;
		return(<Button className="choosebutton" onClick={this.handleClose}> <CloseIcon style={{marginRight:'0.5rem'}}/> Volver al producto</Button>)
    }

    // Controlar que pestaña cargar (Juego, Ganado, Perdido)
    handleDraw(){
        // Variable PROVISIONAL mientras se conecta con la API, define el resultado del sorteo (sorteo = this.state...)
        const { sorteo } = this.state;
        
        // Función para generar array de casillas
        const range = (start, end) => Array.from({length: (end - start)}, (v, k) => k + start);
        /* {[...range(1,numCells+1)].map( num => <Button num={num} />)} */
        
        switch(sorteo){
            case 0:
                // Perdido
                return(
                    <div className="product-lose-wrap">
                        Has perdido
                    </div>
                );
            case 1:
                // Ganado
                return(
                    <div className="product-win-wrap">
                        <Typography className="product-name" component={'h1'} dangerouslySetInnerHTML={{__html:this.props.product.name}}/>
                        <Typography className="product-description" component={'p'}>
                            <img src={globitos} alt="Te lo has ganado" />
                            <Typography  variant="h6" component="h1" className="product-win-title">Te lo has ganado!</Typography>
        
                            {/* Listar casillas marcadas y ganadora en color destacado */}
                            
                            <ProductShippingDialog
                                product={this.props.product} />
                        </Typography>

                    </div>
                );
            case 2:
                // Splash screen
                return(
                    <div className="product-lose-wrap" style={{height: '100%'}}>
                        <CircularProgress color="inherit" size="8rem" />
                    </div>
                );
            default:
                // Jugar
                return(
                    <div className="product-info-wrap">
        
                        <Typography className="product-name" component={'h1'} dangerouslySetInnerHTML={{__html:this.props.product.name}}/>

                        <div className="product-betting-wrap">
                            <Typography component={'h2'} className="product-betting-title">Elige para ganar</Typography>

                            <form onSubmit={this.handleFormSubmit}>
                                <ul className="betting">
                                {[...range(1,this.props.product.cells+1)].map( 
                                    num => <Casilla key={num} num={num} onCheckboxChange={this.handleCheckboxChange} />
                                )}
                                </ul>

                                <div className="buypanel">
                                <div className="left">
                                    {this.precioCasillas()}
                                </div>
                                <div className="right">
                                    {this.botonesElegirJugar()}
                                </div>
                                </div>
                            </form>
                        </div>

                        <ProductPayment handlePayment={this.handlePayment} />
                    </div>
                );
        }
    }

	render(){
        // Ojo sale esto mientras hace fetching!
        if( !this.props.product ) return (
			<div>
				<Button className="choosebutton" onClick={this.handleClickOpen}> <CheckCircleOutlineIcon style={{marginRight:'0.5rem'}}/> Elige</Button>

				<Dialog fullScreen open={this.state.open} onClose={this.handleClose} TransitionComponent={Transition} aria-labelledby="Elige para ganar">
					<AppBarStyled color={'primary'} elevation={0} >
						<Toolbar className="topbar">
							<div style={{flexBasis:'33.33%', display: 'flex', alignItems: 'center'}}>
								<IconButton edge="start" color="inherit" onClick={this.handleClose} aria-label="close">
									<CloseIcon />
								</IconButton>
								
								<Box component={'div'} display={{xs: 'none', md:'block' }}>
									<Typography variant="body1">Elige para ganar</Typography>
								</Box>
							</div>
							
							<Box component={'div'} className={'logo'} style={{flexBasis:'33.33%', textAlign: 'center'}}>
								<Link href={'/'}><img src={logo} alt={'Rocksel'}  css={{maxWidth: '100%', display:'block'}} /></Link>  
							</Box>

							<Box component={'div'} /*display={{xs: 'none', md:'block' }}*/ style={{flexBasis:'33.33%', textAlign: 'right'}} />
						</Toolbar>
					</AppBarStyled>
					
					<BackgroundStyled>
						<ContainerStyled>
							<Grid container>
								<Grid item xs={12} md={12}>
									<CircularProgress color="inherit" size="1.75rem" style={{margin: 'auto'}} />     
								</Grid>
							</Grid>
						</ContainerStyled>
					</BackgroundStyled>

					<Footer display={{xs: 'none', md:'block' }} />
				</Dialog>
			</div>
        )
		return (
			<div>
				<Button className="choosebutton" onClick={this.handleClickOpen}> <CheckCircleOutlineIcon style={{marginRight:'0.5rem'}}/> Elige</Button>

				<Dialog fullScreen open={this.state.open} onClose={this.handleClose} TransitionComponent={Transition} aria-labelledby="Elige para ganar">
					<AppBarStyled color={'primary'} elevation={0} >
						<Toolbar className="topbar">
							<div style={{flexBasis:'33.33%', display: 'flex', alignItems: 'center'}}>
								<IconButton edge="start" color="inherit" onClick={this.handleClose} aria-label="close">
									<CloseIcon />
								</IconButton>
								
								<Box component={'div'} display={{xs: 'none', md:'block' }}>
									<Typography variant="body1">Elige para ganar</Typography>
								</Box>
							</div>
							
							<Box component={'div'} className={'logo'} style={{flexBasis:'33.33%', textAlign: 'center'}}>
								<Link href={'/'}><img src={logo} alt={'Rocksel'}  css={{maxWidth: '100%', display:'block'}} /></Link>  
							</Box>

							<Box component={'div'} /*display={{xs: 'none', md:'block' }}*/ style={{flexBasis:'33.33%', textAlign: 'right'}} />
						</Toolbar>
					</AppBarStyled>
					
					<BackgroundStyled>
						<ContainerStyled>
							<Grid container>
								<Grid item xs={12} md={12}>
									{this.handleDraw()}       
								</Grid>
							</Grid>
						</ContainerStyled>
					</BackgroundStyled>

					<Footer display={{xs: 'none', md:'block' }} />
				</Dialog>
			</div>
		);
	}

}

const BackgroundStyled = styled.div`
    background: #F4F4F4;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 3rem;
`;

const ContainerStyled = styled(Container)`
    min-height: 500px;
    max-width: 1024px;
    background: #fff;
`;


const AppBarStyled = styled(AppBar)`
    position: relative;
`;

const mapStateToProps = ( { User } ) => {
    return {
        user: User.currentUser
    }
}

const mapDispatchToProps = {
    showProductShipping, showProductPayment, fetchDraw, showSignUpWith
}

export default  connect( mapStateToProps,mapDispatchToProps )(ProductPlayDialog);
