import React from "react";
import { connect } from 'react-redux';
import styled from 'styled-components';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import Avatar from '@material-ui/core/Avatar';
import CircularProgress from '@material-ui/core/CircularProgress';
import { openMenu, closeMenu } from '../../Actions/Config';
import { showSignUpWith, showProfile } from '../../Actions/User';
import WrapperMenuItem from './WrapperMenuItem';
import noImage from '../../Assets/Images/no-image.png';
import Link from '../Link';
import theme from '../../theme';

const WrapperMenu = ( { isOpen, user, isFetching, menuLoggedIn, menuLoggedOut, menuHelp, categories, dispatch, anchor='left' } ) => {

  const open = () => {
    dispatch( openMenu() );
  }

  const close = () => {
    dispatch( closeMenu() );
  }

  return (
    <SwipeableDrawer
      anchor={anchor}
      open={isOpen}
      onClose={close}
      onOpen={open}
    >
      <nav
        className={'sidebar-left'}
        style={{width: 250}}
        role="navigation"
        onClick={close}
        onKeyDown={close}>
          {isFetching && <CircularProgress color="inherit" size="1.75rem" />}
          { ( user ) && !isFetching && 
            <UserContainer>
              <Avatar src={user.image} onClick={ () => dispatch( showProfile() ) } style={{marginBottom: '1rem'}}/>
              <UserName  onClick={ () => dispatch( showProfile() ) }>{user.name}</UserName>
              <UserAction  onClick={ () => dispatch( showProfile() ) }>{'Configura tu perfil'}</UserAction>
            </UserContainer>
          }
          { !user && !isFetching && 
            <UserContainer>
              <Avatar src={noImage} style={{marginBottom: '1rem'}}/>
              <UserName onClick={ () => dispatch( showSignUpWith() ) }>Entra o regístrate</UserName>
              <UserAction onClick={ () => dispatch( showSignUpWith() ) }>Comienza</UserAction>
            </UserContainer>
          }
        <Divider />
        <List>
          <WrapperMenuItem text={'Destacados'} href={`/`} tab={'destacados'} key={0} />
          {categories.map( ( category, key ) => (
            <WrapperMenuItem text={category.name} href={`/categoria/${category.slug}/`} tab={category.slug} key={key} />
          ))}
        </List>
        <Divider />
        <List>
          {menuHelp.map( ( item, key ) => (
            <WrapperMenuItem text={item[0]} href={item[1]} key={key} />
          ))}
        </List>
      </nav>
    </SwipeableDrawer>
  )
}

const UserContainer = styled.div`
  margin: 1rem 0;
  display: flex;
  flex-direction: column;
  padding: 0 1rem;
`;

const UserName = styled(Link)`
  font-size: 1.2rem;
  line-height: 1.2;
  font-weight: 500;
  
  &:hover{
    text-decoration: none;
    color: ${theme.palette.ternary.main};
    font-weight: 600;
  }
`;

const UserAction = styled(Link)`
  font-weight: 300;
  font-size: .85rem;
  color: ${theme.palette.black[60]};
`;

const mapStateToProps = ({ Config, User, Categories }) => {
  return { 
    isOpen: Config.menuOpen,
    user: User.currentUser,
    menuLoggedOut: Config.menuLoggedOut, 
    menuLoggedIn: Config.menuLoggedIn,
    menuHelp: Config.menuHelp,
    categories: Categories.items,
    isFetching: User.isFetching
  }
}

export default connect( mapStateToProps )(WrapperMenu);