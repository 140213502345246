import React from "react";
import { connect } from 'react-redux';
import { setCurrentTab } from '../../Actions/Config';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Link from '../Link';
import styled from 'styled-components';
import theme from '../../theme';

const WrapperMenuItem = ({ component, text, href, tab, key, dispatch }) => {

  const onClick = ( event ) => {
    if( tab )
      dispatch( setCurrentTab(tab) );
    return false;
  }

  return (
    <ListItem onClick={onClick} component={Link} href={href} key={key}>
      <MenuItemLink primary={text} />
    </ListItem>
  );
}

const MenuItemLink = styled(ListItemText)`
  font-size: .8rem;
  color: ${theme.palette.black[60]};
  text-decoration: none;
  font-weight: 500;
`;

const mapStateToProps = ( state ) => {
  return { 
  }
}

export default connect( mapStateToProps )(WrapperMenuItem);