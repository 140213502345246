import React from "react";
import { connect } from 'react-redux';

import styled from 'styled-components';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import IconButton from '@material-ui/core/IconButton';
//import CloseIcon from '@material-ui/icons/Close';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import SettingsIcon from '@material-ui/icons/Settings';
import HelpIcon from '@material-ui/icons/Help';
import Typography from '@material-ui/core/Typography';
import theme from '../theme';

import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Slide from '@material-ui/core/Slide';
import { makeStyles } from '@material-ui/core/styles';

import Box from '@material-ui/core/Box';
import Link from './Link';
import logo from '../Assets/Images/logo-rocksel.png';
import Footer from './Layout/Footer';

const useStyles = makeStyles((theme) => ({
    appBar: {
        position: 'relative',
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        marginBottom: '1rem'
    },
    formControl: {
        marginTop: theme.spacing(2),
        minWidth: 120,
    },
    formControlLabel: {
        marginTop: theme.spacing(1),
        paddingTop: theme.spacing(1),
    },
    separar: {
        margin: '1rem 0'
    },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const EditPaymentDialog = ( {user, buttonText='Editar', dialogTitle='Editar método de pago'} ) => {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <>
            <StyledButton onClick={handleClickOpen}>{buttonText}</StyledButton>
            <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
                <AppBar color={'primary'} elevation={0} className={classes.appBar}>
                    <Toolbar className="topbar">
                        <div style={{flexBasis:'33.33%', display: 'flex', alignItems: 'center'}}>
                            <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                                <ArrowBackIcon />
                            </IconButton>

                            <Box component={'div'} display={{xs: 'none', md:'block' }}>
                                <Typography variant="body1">Tus pagos</Typography>
                            </Box>
                        </div>
                        
                        <Box component={'div'} className={'logo'} style={{flexBasis:'33.33%', textAlign: 'center'}}>
                            <Link href={'/'}><img src={logo} alt={'Rocksel'}  css={{maxWidth: '100%', display:'block'}}/></Link>  
                        </Box>

                        <Box component={'div'} style={{flexBasis:'33.33%', textAlign: 'right'}}>
                            <IconButton autoFocus color="inherit" onClick={handleClose}>
                                <HelpIcon />
                            </IconButton>
                            <IconButton autoFocus color="inherit" onClick={handleClose}>
                                <SettingsIcon/>
                            </IconButton>
                        </Box>
                    </Toolbar>
                </AppBar>

                <BackgroundStyled>
                    <ContainerStyled>
                        <Grid container>
                            <Grid item xs={12} md={12}>
                                <div  style={styles.contenedorLogin}>
                                    <Typography  variant="h6" component="h1">{dialogTitle}</Typography>

                                    <Typography variant="body1" color="textSecondary" className={classes.separar}>Te cobraremos por casilla y sólo cuando pulses en jugar dentro de un producto.</Typography>
                                    
                                    <Link className={classes.separar} style={{color: '#01a299'}}>Quiero saber como funciona</Link>
                                    
                                    <form className={classes.form} noValidate>
                                        <FormControl className={classes.formControl}>
                                            <TextField
                                                id="outlined-helperText"
                                                label="Titular"
                                                defaultValue={user.name}
                                                variant="outlined"
                                            />
                                        </FormControl>

                                        <FormControl className={classes.formControl}>
                                            <TextField
                                                id="outlined-helperText"
                                                label="Número de tarjeta"
                                                defaultValue=""
                                                variant="outlined"
                                            />
                                        </FormControl>

                                        <div style={{display: 'flex', justifyContent: 'space-between'}}>
                                            <FormControl variant="outlined" className={classes.formControl} style={{flexBasis: '49%'}}>
                                                <InputLabel id="demo-simple-select-outlined-label">Mes</InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-outlined-label"
                                                    id="demo-simple-select-outlined"
                                                    label="Mes"
                                                >
                                                    <MenuItem value="">
                                                        <em>None</em>
                                                    </MenuItem>
                                                    <MenuItem value={1}>01</MenuItem>
                                                    <MenuItem value={2}>02</MenuItem>
                                                    <MenuItem value={3}>03</MenuItem>
                                                </Select>
                                                <FormHelperText>Vencimiento</FormHelperText>
                                            </FormControl>

                                            <FormControl variant="outlined" className={classes.formControl} style={{flexBasis: '49%'}}>
                                                <InputLabel id="demo-simple-select-outlined-label">Año</InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-outlined-label"
                                                    id="demo-simple-select-outlined"
                                                    label="Año"
                                                >
                                                    <MenuItem value="">
                                                        <em>None</em>
                                                    </MenuItem>
                                                    <MenuItem value={2020}>2020</MenuItem>
                                                    <MenuItem value={2021}>2021</MenuItem>
                                                    <MenuItem value={2022}>2022</MenuItem>
                                                </Select>
                                            </FormControl>                                            
                                        </div>


                                        <FormControlLabel
                                            control={
                                            <Checkbox
                                                name="checkedB"
                                                color="primary"
                                            />
                                            }
                                            label="Establecer pago rápido cuando pulses en jugar, siempre tendrás unos segundos para cancelar si te equivocas."
                                            className={classes.formControlLabel}
                                        />
                                    </form>

                                    <StyledButton onClick={handleClose}>{'GUARDAR TARJETA'}</StyledButton>
                                </div>
                            </Grid>
                        </Grid>
                    </ContainerStyled>
                </BackgroundStyled>

                <Footer/>
            </Dialog>
        </>
    );
}

const styles = {
    closeButton: {
        position: 'absolute',
        top: '0.5rem',
        left: '0.5rem',
        color: '#9e9e9e',
    },
    contenedorLogin:{
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: 'column',
        height: '100%',
        width: '65%',
        margin: '2rem auto 2rem auto',
        justifyContent: 'center'
    },
};


const BackgroundStyled = styled.div`
    background: #F4F4F4;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 3rem;
`;

const ContainerStyled = styled(Container)`
    min-height: 500px;
    max-width: 1024px;
    background: #fff;
`;

const StyledButton = styled(Button)`
    background: ${theme.palette.ternary.main};
    color: ${theme.palette.ternary.contrastText};
    border-radius: 25px;
    padding: .5rem 2rem;
    margin: 1rem 0 1rem 0;
    pointer-events: initial;
    width: fit-content;
    text-transform: uppercase;

    &:hover{
        background: ${theme.palette.primary.main};
        color: ${theme.palette.primary.contrastText};
    }
`;

const mapStateToProps = ({ User }) => {
    return {
        user: User.currentUser
    }
}

export default connect( mapStateToProps )(EditPaymentDialog);
