export const URL = 'https://api.rocksel.com/wp-json/rocksel/v1/';

export const getPages = () => new Promise(
  function (resolve, reject) {
    fetch(URL + 'pages/', {
      method: 'GET',
    })
    .then( (res) => res.json() )
    .then( (json) => {
      resolve( json )
    })
    .catch( (error) => {
      console.error(error)
    })
  }
);

export const getHome = () => new Promise(
  function (resolve, reject) {
    fetch(URL + 'home/', {
      method: 'GET',
    })
    .then( (res) => res.json() )
    .then( (json) => {
      resolve( json )
    })
    .catch( (error) => {
      console.error(error)
    })
  }
);

export const getProducts = () => new Promise(
  function (resolve, reject) {
    fetch(URL + 'products/', {
      method: 'GET',
    })
    .then( (res) => res.json() )
    .then( (json) => {
      resolve( json )
    })
    .catch( (error) => {
      console.error(error)
    })
  }
);

export const getCategories = () => new Promise(
  function (resolve, reject) {
    fetch(URL + 'categories/', {
      method: 'GET',
    })
    .then( (res) => res.json() )
    .then( (json) => {
      resolve( json )
    })
    .catch( (error) => {
      console.error(error)
    })
  }
);

export const getUser = () => new Promise(
  function (resolve, reject) {
    fetch(URL + 'user/', {
      method: 'GET',
    })
    .then( (res) => res.json() )
    .then( (json) => {
      resolve( json )
    })
    .catch( (error) => {
      console.error(error)
    })
  }
);

export const loginRegister = (response) => new Promise(
  function (resolve, reject) {

    const data = { response: JSON.parse( JSON.stringify( response ) ) };

    fetch(URL + 'loginregister', {
      method: 'POST',
      body: JSON.stringify( data ),
      headers: { 
        'Content-Type': 'application/json', 
      },
    })
      .then( (res) => res.json() )
      .then( (json) => {
        resolve( json );
      })
      .catch( (error) => {
        console.error(error);
        reject( error );
      });
  }
);

export const jugar = (user, draw) => new Promise(
  function (resolve, reject) {
    const data = { 
      product: 42,
      'numbers[]': 10,
      //response: JSON.parse( JSON.stringify( draw ) ) 
    };

    fetch(URL + 'play?win=1', {
      method: 'POST',
      body: JSON.stringify( data ),
      headers: { 
        'Content-Type': 'application/json',
        'email': user.email, // 'jatejada@si2.info',
        'token': user.token //'5912e13df1ccac1b1c16039606de56a2d4bd1ce1a09100a07b86e14eb357cf57'
      },
    })
      .then( (res) => res.json() )
      .then( (json) => {
        resolve( json );
      })
      .catch( (error) => {
        console.error(error);
        reject( error );
      });
  }
);

