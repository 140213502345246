import { 
  SHOW_PRODUCTSHIPPING,
  HIDE_PRODUCTSHIPPING,
  TOGGLE_PRODUCTSHIPPING,
  SHOW_PRODUCTPAYMENT,
  HIDE_PRODUCTPAYMENT,
  TOGGLE_PRODUCTPAYMENT,
  REQUEST_PRODUCTS, 
  RECEIVE_PRODUCTS, 
  ERROR_RECEIVE_PRODUCTS 
} from '../Actions/Products';

const initialState = {
  productPaymentOpen: false,
  productShippingOpen: false,
  isFetching: false,
  items: [],
  lastUpdated: null,
  error: null,
};

const Products = ( state = initialState, action ) => {
  switch (action.type) {

    case SHOW_PRODUCTPAYMENT:
      return {
          ...state,
          productPaymentOpen: true
      };
    case HIDE_PRODUCTPAYMENT:
        return {
            ...state,
            productPaymentOpen: false
        };
    case TOGGLE_PRODUCTPAYMENT:
        return {
            ...state,
            productPaymentOpen: ! state.productPaymentOpen,
        };
    case SHOW_PRODUCTSHIPPING:
      return {
          ...state,
          productShippingOpen: true
      };
    case HIDE_PRODUCTSHIPPING:
        return {
            ...state,
            productShippingOpen: false
        };
    case TOGGLE_PRODUCTSHIPPING:
        return {
            ...state,
            productShippingOpen: ! state.productShippingOpen,
        };
    case REQUEST_PRODUCTS:
      return {
        ...state,
        isFetching: true,
      };
    case RECEIVE_PRODUCTS:
      return {
        ...state,
        isFetching: false,
        items: action.items,
        lastUpdated: Date.now(),
      };
    case ERROR_RECEIVE_PRODUCTS:
      return {
        ...state,
        isFetching: false,
        error: action.error,
      };

    default:
      return state
  }
}

export default Products