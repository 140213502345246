import * as API from '../API/API';

export const REQUEST_CATEGORIES = 'REQUEST_CATEGORIES';
export const requestCategories = () => {
  return {
    type: REQUEST_CATEGORIES,
    isFetching: true,
  }
}

export const RECEIVE_CATEGORIES = 'RECEIVE_CATEGORIES';
export const receiveCategories = items => {
  return {
    type: RECEIVE_CATEGORIES,
    items: items,
  }
};

export const ERROR_RECEIVE_CATEGORIES = 'RECEIVE_CATEGORIES';
export const errorReceiveCategories = ( error ) => {
  return {
    type: ERROR_RECEIVE_CATEGORIES,
    error: error,
  }
}

export function fetchCategories(){
  return ( dispatch, getState ) => {
    dispatch(requestCategories())
    return API.getCategories()
      .then( items => dispatch(receiveCategories(items)) )
      .catch( error => dispatch(errorReceiveCategories(error)) )
  }
}