import React, { Component } from "react";
import { connect } from 'react-redux';
import styled from 'styled-components';
import ReactHtmlParser from 'react-html-parser';

// Estructura base del return
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import CircularProgress from '@material-ui/core/CircularProgress';
import Container from '@material-ui/core/Container';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

// Definiciones de estilos
import '../../Assets/CSS/ProductDrawPlay.css';
import Breadcrumbs from '../Breadcrumbs';
import '../../Assets/CSS/ProductBetting.css';

// Conponentes propios
import ProductPlayDialog from '../ProductPlayDialog'
import ProductSlider from '../ProductSlider';

// Actions
import { hideTabs } from '../../Actions/Config';

// Componente para mostrar producto
class ProductView extends Component{

    constructor(props){
        super(props);

        // Ocultar tabs de categorías
        if( this.props.tabs ) this.props.hideTabs();
    }

    // Generar breadcrumbs
    hanselygretel(){
        const breadcrumbsItems = [
            [
                'Inicio',
                '/',
            ],
        ];

        if( this.props.categories ){
            this.props.categories.map( category => {
                breadcrumbsItems.push([
                    category.name,
                    `/categoria/${category.slug}/`,
                ]);
                return true;
            });    
        }

        return breadcrumbsItems;
    }

    render(){
        // Ojo sale esto mientras hace fetching!
        if( !this.props.product ) return (
            <ContainerStyled>
                <Grid container> 
                    <CircularProgress color="inherit" size="1.75rem" style={{margin: 'auto'}} /> 
                </Grid>
            </ContainerStyled>
        )

        return(
            <>
                <ContainerStyled>
                    <Grid container>
                        <Grid item xs={12} md={6}>
                            <ProductSlider product={this.props.product}/>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <div className="product-info-wrap">
                                <Breadcrumbs items={this.hanselygretel()} />
                
                                <Typography className="product-name" component={'h1'} dangerouslySetInnerHTML={{__html:this.props.product.name}}/>
                                <Typography className="product-description" component={'p'} dangerouslySetInnerHTML={{__html:this.props.product.excerpt}}/>
                
                                <Accordion className="product-expansion-panel">
                                    <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                    >
                                        <Typography className="product-expansion-heading">MÁS INFORMACIÓN DEL PRODUCTO</Typography>
                                    </AccordionSummary>

                                    <AccordionDetails>
                                        <Typography className="product-expansion-details" component="span">
                                            {ReactHtmlParser(this.props.product.description)}
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>
                
                                <div className="product-betting-wrap">
                                    <form onSubmit={this.handleFormSubmit}>
                                        <div className="choosepanel">
                                            <div className="left">
                                                <Typography variant="h6" component="h1" color="textPrimary">{this.props.product.price} €</Typography>
                                                <Typography variant="body2" color="textSecondary">Precio por casilla</Typography>
                                            </div>
                                            <div className="right">
                                                <ProductPlayDialog 
                                                    product={this.props.product}
                                                />
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                </ContainerStyled>
            </>
        )
    }
}

const ContainerStyled = styled(Container)`
    min-height: 500px;
    max-width: 1024px;
    margin-top: 2rem;
`;

const mapStateToProps = ( { Products, Categories, Config }, ownProps ) => {
    const { product_slug } = ownProps.match.params;
    const product = Products.items.find( product => product.slug === product_slug );
    return {
        tabs: Config.showTabs,
        product: product,
        categories: product && Categories.items.filter( category => product.category.includes( category.slug ) ),
    }
}

const mapDispatchToProps = {
    hideTabs
}
export default connect( mapStateToProps,mapDispatchToProps )(ProductView);