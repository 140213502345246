import { REQUEST_CATEGORIES, RECEIVE_CATEGORIES, ERROR_RECEIVE_CATEGORIES } from '../Actions/Categories';

const initialState = {
  isFetching: false,
  items: [],
  lastUpdated: null,
  error: null,
};

const Categories = ( state = initialState, action ) => {
  switch (action.type) {

    case REQUEST_CATEGORIES:
      return {
        ...state,
        isFetching: true,
      };
    case RECEIVE_CATEGORIES:
      return {
        ...state,
        isFetching: false,
        items: action.items,
        lastUpdated: Date.now(),
      };
    case ERROR_RECEIVE_CATEGORIES:
      return {
        ...state,
        isFetching: false,
        error: action.error,
      };

    default:
      return state
  }
}

export default Categories