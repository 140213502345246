import React, { Component } from "react";
import { connect } from 'react-redux';

import { showSignUpWith, hideSignUpWith, signUpUser,fetchUser } from '../Actions/User';
import SignUpWithDialog from './SignUpWithDialog';

class SignUpWith extends Component{
    constructor(props){
        super(props);
        this.handleClickOpen = this.handleClickOpen.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.responseGoogle = this.responseGoogle.bind(this);
        this.responseFacebook = this.responseFacebook.bind(this);
    }

    handleClickOpen(){
        this.props.showSignUpWith();
    }

    handleClose(){
        this.props.hideSignUpWith();
    }

    responseGoogle(response){
        console.log('Response Google');
        console.log(response);

        if( !response.error){
            /*let nuevoUsuario = {
                image: response.profileObj.imageUrl,
                name: response.profileObj.name,
                email: response.profileObj.email,
                authmethod: 'google',
                address: '',
                country: '',
                city: '',
                state: '',
                postcode: '',
                phone: '',
                cardtype: '',
                lastnumber: '',
                cardexp: '',
                token: response.accessToken,
                pagos:[]
            }
            
            this.props.signUpUser( nuevoUsuario );*/

            console.log('Fetch User');
            this.props.fetchUser(response);
            
            this.props.hideSignUpWith();           
        }else{
            console.log(response.details);
        }
    }

    responseFacebook(response){
        console.log('Response Facebook');
        console.log(response);

        if(response && response.accessToken){
            /*let nuevoUsuario = {
                image: response.picture.data.url,
                name: response.name,
                email: response.email,
                authmethod: 'facebook',
                address: '',
                country: '',
                city: '',
                state: '',
                postcode: '',
                phone: '',
                cardtype: '',
                lastnumber: '',
                cardexp: '',
                token: response.accessToken,
                pagos:[]
            }
            
            this.props.signUpUser( nuevoUsuario );*/

            console.log('Fetch User');
            this.props.fetchUser(response);

            this.props.hideSignUpWith();            
        }
    }

    render(){
        return(
            <>
                <SignUpWithDialog 
                    open={this.props.signUpWithOpen} 
                    onClose={this.handleClose} 
                    responseGoogle={this.responseGoogle}
                    responseFacebook={this.responseFacebook}
                />
            </>
        )
    }
}

const mapStateToProps = ({ User }) => {
    return { 
        signUpWithOpen: User.signUpWithOpen
    }
}

const mapDispatchToProps = {
    showSignUpWith, hideSignUpWith, signUpUser, fetchUser
}

export default connect( mapStateToProps,mapDispatchToProps )(SignUpWith);