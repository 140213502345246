import React from "react";
import { connect } from 'react-redux';
import Link from '@material-ui/core/Link';
import { Link as LinkRouter } from 'react-router-dom';

const LinkComponent = ({ href, className, style, children, display, onClick, "aria-current": ariaCurrent, }) => {
  return (
    <Link
      component={LinkRouter}
      to={href}
      className={className}
      style={style}
      aria-current={ariaCurrent}
      display={display}
      onClick={onClick}
    >
      {children}
    </Link>
  );
};

const mapStateToProps = ( state ) => { return state; }

export default connect(mapStateToProps)(LinkComponent);