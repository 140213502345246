import { 
  SET_CURRENT_TAB, 
  SHOW_TABS, 
  HIDE_TABS, 
  TOGGLE_TABS, 
  OPEN_MENU, 
  CLOSE_MENU, 
  TOGGLE_MENU, 
  SHOW_WELCOME, 
  HIDE_WELCOME,
  TOGGLE_WELCOME,
  SHOW_WHOITWORKS_BUTTON,
  HIDE_WHOITWORKS_BUTTON,
  TOGGLE_WHOITWORKS_BUTTON 
} from '../Actions/Config';

const initialState = {
  menuOpen: false,
  showTabs: true,
  showWelcome: true,
  showWhoItWorksButton: false,
  currentTab: 'destacados',
  menuLoggedOut: [
    [
      "Iniciar sesión",
      "/iniciar-sesión"
    ],
    [
      "Registrarme",
      "/registro"
    ],
  ],
  menuLoggedIn: [
    [
      "Mi perfil",
      "/perfil"
    ],
    [
      "Pagos",
      "/pagos"
    ],
  ],
  menuHelp: [
    [
      "Cómo funciona Rocksel",
      "/como-funciona"
    ],
    [
      "Ayuda",
      "/ayuda"
    ],
  ]
};

const Config = ( state = initialState, action ) => {
  switch (action.type) {
    case SET_CURRENT_TAB:
      return {
        ...state,
        currentTab: action.tab,
      };
    case OPEN_MENU:
      return {
        ...state,
        menuOpen: true,
      };
    case CLOSE_MENU:
      return {
        ...state,
        menuOpen: false,
      };
    case TOGGLE_MENU:
      return {
        ...state,
        menuOpen: ! state.menuOpen,
      };

    case SHOW_TABS:
      return {
        ...state,
        showTabs: true,
      };
    case HIDE_TABS:
      return {
        ...state,
        showTabs: false,
      };
    case TOGGLE_TABS:
      return {
        ...state,
        showTabs: ! state.showTabs,
      };

    case SHOW_WELCOME:
      return {
        ...state,
        showWelcome: true,
      };
    case HIDE_WELCOME:
      return {
        ...state,
        showWelcome: false,
      };
    case TOGGLE_WELCOME:
      return {
        ...state,
        showWelcome: ! state.showWelcome,
      };

    case SHOW_WHOITWORKS_BUTTON:
      return {
        ...state,
        showWhoItWorksButton: true,
      };
    case HIDE_WHOITWORKS_BUTTON:
      return {
        ...state,
        showWhoItWorksButton: false,
      };
    case TOGGLE_WHOITWORKS_BUTTON:
      return {
        ...state,
        showWhoItWorksButton: ! state.showWhoItWorksButton,
      };

    default:
      return state
  }
}

export default Config