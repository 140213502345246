import * as API from '../API/API';

export const SHOW_PRODUCTPAYMENT = 'SHOW_PRODUCTPAYMENT';
export const showProductPayment = () => {
    return {
        type: SHOW_PRODUCTPAYMENT
    }
}

export const HIDE_PRODUCTPAYMENT = 'HIDE_PRODUCTPAYMENT';
export const hideProductPayment = () => {
    return {
        type: HIDE_PRODUCTPAYMENT
    }
}

export const TOGGLE_PRODUCTPAYMENT = 'TOGGLE_PRODUCTPAYMENT';
export const toggleProductPayment = () => {
    return {
        type: TOGGLE_PRODUCTPAYMENT
    }
}
export const SHOW_PRODUCTSHIPPING = 'SHOW_PRODUCTSHIPPING';
export const showProductShipping = () => {
    return {
        type: SHOW_PRODUCTSHIPPING
    }
}

export const HIDE_PRODUCTSHIPPING = 'HIDE_PRODUCTSHIPPING';
export const hideProductShipping = () => {
    return {
        type: HIDE_PRODUCTSHIPPING
    }
}

export const TOGGLE_PRODUCTSHIPPING = 'TOGGLE_PRODUCTSHIPPING';
export const toggleProductShipping = () => {
    return {
        type: TOGGLE_PRODUCTSHIPPING
    }
}

export const REQUEST_PRODUCTS = 'REQUEST_PRODUCTS';
export const requestProducts = () => {
  return {
    type: REQUEST_PRODUCTS,
    isFetching: true,
  }
}

export const RECEIVE_PRODUCTS = 'RECEIVE_PRODUCTS';
export const receiveProducts = items => {
  return {
    type: RECEIVE_PRODUCTS,
    items: items,
  }
};

export const ERROR_RECEIVE_PRODUCTS = 'RECEIVE_PRODUCTS';
export const errorReceiveProducts = ( error ) => {
  return {
    type: ERROR_RECEIVE_PRODUCTS,
    error: error,
  }
}

export function fetchProducts(){
  return ( dispatch, getState ) => {
    dispatch(requestProducts())
    return API.getProducts()
      .then( items => dispatch(receiveProducts(items)) )
      .catch( error => dispatch(errorReceiveProducts(error)) )
  }
}