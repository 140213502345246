import React, { Component } from "react";
import { connect } from 'react-redux';

import { showProfile, hideProfile } from '../Actions/User';
import ProfileDialog from './ProfileDialog';

class Profile extends Component{
    constructor(props){
        super(props);
        this.handleClickOpen = this.handleClickOpen.bind(this);
        this.handleClose = this.handleClose.bind(this);
    }

    handleClickOpen(event){
        event.preventDefault();
        this.props.showProfile();
    }

    handleClose(){
        this.props.hideProfile();
    }

    render(){
        return(
            <>
                <ProfileDialog 
                    open={this.props.profileOpen} 
                    onClose={this.handleClose}
                />
            </>
        )
    }
}

const mapStateToProps = ({ User }) => {
    return { 
        profileOpen: User.profileOpen
    }
}

const mapDispatchToProps = {
    showProfile, hideProfile
}

export default connect( mapStateToProps,mapDispatchToProps )(Profile);