// React Redux
import React from "react";
import { connect } from 'react-redux';
import styled from 'styled-components';

// Material UI
import { makeStyles } from '@material-ui/core/styles';
import theme from '../theme';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import InputLabel from '@material-ui/core/InputLabel';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

const useStyles = makeStyles((theme) =>({
    root: {
        flexGrow: 1,
    },
    box:{
        border: 'solid 1px rgba(0, 0, 0, 0.12)',
        borderRadius: '4px',
        marginBottom: '1rem'
    },
    mb0:{
        marginBottom: '0'
    },
    circlebg:{
        backgroundColor: '#d4c8b7'
    },
    separar: {
        margin: '1rem 0'
    },
    btnredondo:{
        borderRadius: '25px',
        padding: '.5rem 2rem',
        margin: '1rem 0 1rem 0'
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        marginBottom: '1rem'
    },
    formControl: {
        marginTop: theme.spacing(2),
        minWidth: 120,
    },
    formControlLabel: {
        marginTop: theme.spacing(1),
    },
}));

const SettingsBox = ({ user }) =>{

    const classes = useStyles();

    const [values, setValues] = React.useState({
        password: '',
        showPassword: false,
    });

    const handleChangePassword = (prop) => (event) => {
        setValues({ ...values, [prop]: event.target.value });
    };
    
    const handleClickShowPassword = () => {
        setValues({ ...values, showPassword: !values.showPassword });
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    return(
        <>
        <Box className={classes.box} p={2}>
            <Grid container wrap="nowrap" spacing={2}>
                <Grid item>
                    <Avatar src={user.image} className={classes.circlebg} />
                </Grid>
                <Grid item xs>
                    <Typography gutterBottom variant="subtitle1" className={classes.mb0}> {user.name} </Typography>
                    <Typography variant="body2" color="textSecondary"> Configuración de cuenta </Typography>
                    {/*<Typography variant="body2" color="textSecondary"> {user.token} </Typography>*/}
                </Grid>
            </Grid>

            <Typography variant="body1" color="textPrimary" className={classes.separar} gutterBottom>Datos de acceso</Typography>

            <form className={classes.form} noValidate>
                <FormControl className={classes.formControl}>
                    <TextField
                        id="outlined-helperText"
                        label="Email"
                        defaultValue={user.email}
                        variant="outlined"
                    />
                    </FormControl>
                    <FormControl className={classes.formControl} variant="outlined">
                        <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
                        <OutlinedInput
                            id="outlined-adornment-password"
                            label="Password"
                            type={values.showPassword ? 'text' : 'password'}
                            value={values.password}
                            onChange={handleChangePassword('password')}
                            endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                                edge="end"
                                >
                                {values.showPassword ? <Visibility /> : <VisibilityOff />}
                                </IconButton>
                            </InputAdornment>
                            }
                        />
                    </FormControl>
            </form>

            <Typography variant="body1" color="textSecondary" className={classes.separar} gutterBottom>Estos datos se utilizan para que puedas acceder a tu cuenta en cualquier dispositivo.</Typography>

            <ContenedorBotones>
                {/* <Button className={classes.btnredondo}>CANCELAR</Button> */}
                <StyledButton onClick={() => { console.log('Guardar configuración de cuenta...') }}>{'GUARDAR'}</StyledButton>
            </ContenedorBotones>
        </Box>
        </>
    );
}

const ContenedorBotones = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
`;

const StyledButton = styled(Button)`
    background: ${theme.palette.ternary.main};
    color: ${theme.palette.ternary.contrastText};
    border-radius: 25px;
    padding: .5rem 2rem;
    margin: 1rem 0 1rem 0;
    pointer-events: initial;

    &:hover{
        background: ${theme.palette.primary.main};
        color: ${theme.palette.primary.contrastText};
    }
`;

const mapStateToProps = ( { User }) => {
    return {
        user: User.currentUser
    }
}

export default connect(mapStateToProps)(SettingsBox);