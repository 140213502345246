import React from "react";
import { connect } from 'react-redux';
import { Switch,  Route } from "react-router-dom";
import Home from "../Views/Home";
import Category from "../Views/Category";
import Product from "../Views/Product";
import TabsNavigation from '../TabsNavigation';
import Welcome from '../Views/Welcome';
import Privacy from '../Views/Privacy';

const Container = ( { showWelcome } ) => {
  return(
    <>
      <TabsNavigation />
      <Switch>
        <Route path="/producto/:product_slug" component={Product} />
        <Route path="/categoria/:category_slug" component={Category} />
        <Route path="/privacidad" component={Privacy} />
        <Route exact path="/" component={Home} />
      </Switch>
      { showWelcome && (<Welcome />)}      
    </>
  );
}

const mapStateToProps = ( { Config } ) => {
  return { 
    showWelcome: Config.showWelcome,
  }
}

export default connect(mapStateToProps)(Container);