import { 
    SHOW_SIGNUPWITH,
    HIDE_SIGNUPWITH,
    TOGGLE_SIGNHUPWITH,
    SHOW_SIGNUPEMAIL,
    HIDE_SIGNUPEMAIL,
    TOGGLE_SIGNUPEMAIL,
    SHOW_SIGNIN,
    HIDE_SIGNIN,
    TOGGLE_SIGNIN,
    SHOW_PROFILE,
    HIDE_PROFILE,
    TOGGLE_PROFILE,
    SET_USER_TOKEN,
    RESET_USER_TOKEN, 
    REQUEST_USER,
    RECEIVE_USER,
    ERROR_RECEIVE_USER,
    REQUEST_DRAW,
    RECEIVE_DRAW,
    ERROR_RECEIVE_DRAW,
    LOGOUT_USER,
    SIGNUP_USER
} from '../Actions/User';

const initialState = {
    signUpWithOpen: false,
    signUpEmailOpen: false,
    signinOpen: false,
    profileOpen: false,
    /*
    currentDraw:{
        product: '42',
        numbers: [
            {10},
            {15}
        ]
    }
    */

    /*currentUser: {
        image: 'https://www.gravatar.com/avatar/a28de25cd230438ab2eea1bdfafd7dc8',
        name: 'Daniel Jiménez',
        email: 'djimenez@si2.info',
        authmethod: '',
        google_id: '',
        facebook_id: '',
        token: 'a28de25cd230438ab2eea1bdfafd7dc8',
        shipping:{
                address: 'Calle San Rómulo, 51 - 3ºA',
                country: 'España',
                city: 'Granada',
                state: 'Granada',
                postcode: '18013',
                phone: '958123456',
        }  ,
        payment:{
                name: 'Daniel Jiménez',
                cardtype: 'VISA',
                number: '',
                lastnumber: '2033',
                cardexp: '06/20',
        },
        shipments: [
            {
                id: 1,
                producto: 'Xiaomi Mi 9',
                casillas: '2',
                fecha: '06/05/2020',
                precio: '30'
            },
            {
                id: 2,
                producto: 'Apple AirPods',
                casillas: '1',
                fecha: '06/05/2020',
                precio: '10'
            },
        ],
        payments: [
            {
                id: 1,
                producto: 'Xiaomi Mi 9',
                fecha: '06/05/2020',
                estado: 'Enviado'
            },
            {
                id: 2,
                producto: 'Apple AirPods',
                fecha: '06/05/2020',
                estado: 'Entregado'
            },
        ]
    }*/
};

const User = ( state = initialState, action ) => {
    switch (action.type) {
        // Registro con redes sociales
        case SHOW_SIGNUPWITH:
            return {
                ...state,
                signUpWithOpen: true
            };
        case HIDE_SIGNUPWITH:
            return {
                ...state,
                signUpWithOpen: false
            };
        case TOGGLE_SIGNHUPWITH:
            return {
                ...state,
                signUpWithOpen: ! state.signupWithOpen,
            };
        // Registro por email
        case SHOW_SIGNUPEMAIL:
            return {
                ...state,
                signUpEmailOpen: true
            };
        case HIDE_SIGNUPEMAIL:
            return {
                ...state,
                signUpEmailOpen: false
            };
        case TOGGLE_SIGNUPEMAIL:
            return {
                ...state,
                signUpEmailOpen: ! state.signupEmailOpen,
            };
        // Login
        case SHOW_SIGNIN:
            return {
                ...state,
                signInOpen: true
            };
        case HIDE_SIGNIN:
            return {
                ...state,
                signInOpen: false
            };
        case TOGGLE_SIGNIN:
            return {
                ...state,
                signInOpen: ! state.signInOpen,
            };
        // Perfil de usuario
        case SHOW_PROFILE:
            return {
                ...state,
                profileOpen: true
            };
        case HIDE_PROFILE:
            return {
                ...state,
                profileOpen: false
            };
        case TOGGLE_PROFILE:
            return {
                ...state,
                profileOpen: ! state.profileOpen,
            };
        // Operaciones de usuario
        case SET_USER_TOKEN:
            return {
                ...state,
                token: action.token
            };
        case RESET_USER_TOKEN:
            return {
                ...state,
                token: null
            };
        case REQUEST_USER:
            return {
                ...state,
                isFetching: true,
            };
        case RECEIVE_USER:
            return {
                ...state,
                isFetching: false,
                currentUser: action.currentUser,
            };
        case ERROR_RECEIVE_USER:
            return {
                ...state,
                isFetching: false,
                error: action.error,
            };
        case LOGOUT_USER:
            return {
                ...state,
                currentUser: null
            };
        case SIGNUP_USER:
            return {
                ...state,
                currentUser: action.currentUser
            };
        case REQUEST_DRAW:
            return {
                ...state,
                isFetching: true,
            };
        case RECEIVE_DRAW:
            return {
                ...state,
                isFetching: false,
                currentDraw: action.currentDraw,
            };
        case ERROR_RECEIVE_DRAW:
            return {
                ...state,
                isFetching: false,
                error: action.error,
            };
        default:
            return state
    }
};

export default User