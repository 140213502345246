import React from "react";
import { connect } from 'react-redux';
import Container from '@material-ui/core/Container';

const PrivacyView = ({ dispatch }) => {
  return (
    <Container maxWidth="md">
      <h1>Privacidad</h1>
      <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse non aliquet mauris. Phasellus quis enim efficitur, fermentum mauris laoreet, dapibus felis. Curabitur blandit, dolor dapibus tincidunt accumsan, metus justo pharetra enim, vel tincidunt velit leo vel nisl. Vestibulum euismod aliquet gravida. Nulla eu ligula vitae metus molestie tristique. Mauris orci est, mattis ac tortor in, mollis placerat libero. Sed aliquam posuere metus in sodales.</p>
      <p>Cras non neque mattis enim faucibus consequat eu et est. Morbi ut pharetra arcu, vitae aliquam odio. Nulla felis elit, accumsan sit amet lectus non, porttitor auctor turpis. Suspendisse in felis feugiat, congue metus ut, imperdiet felis. Duis quam arcu, venenatis eget magna eget, convallis pellentesque turpis. Aliquam varius ante non eros tempus, ac porttitor ante rutrum. Proin enim erat, sagittis sed elit ac, faucibus aliquam ex. Mauris et orci quis elit aliquam pellentesque non id purus. Duis viverra turpis a aliquam maximus. Ut congue lorem quis enim convallis, sed facilisis massa mollis. Maecenas nec mi et velit commodo pretium a quis eros. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Curabitur in mauris at nisi condimentum eleifend nec vel arcu. Cras non porttitor mi, sit amet dictum magna. Vestibulum efficitur, sapien a ultricies feugiat, mauris leo tincidunt mauris, a vehicula velit felis sit amet ex. Nulla ultrices posuere justo sed consectetur.</p>
      <p>Duis placerat neque magna, sit amet dapibus odio porttitor ut. Quisque lacus velit, maximus non enim id, finibus posuere libero. Nulla vitae sagittis libero, at placerat nulla. Etiam iaculis euismod velit in efficitur. In nec varius diam. Vivamus mi ex, posuere et mattis nec, sagittis eu mi. Nulla sit amet congue justo, lacinia viverra sapien. Pellentesque venenatis sapien libero, at interdum eros congue non. Sed mattis aliquet elit, non ullamcorper urna ultrices id. Pellentesque vel ultrices mauris, quis sagittis risus. Fusce a urna quam. Nunc sollicitudin, mi et viverra porttitor, velit ligula fermentum mauris, ut gravida risus est nec metus. Aliquam maximus lectus quis orci porttitor bibendum. Praesent sit amet tempus dolor, vel consequat nibh. Nulla finibus, ligula ac pellentesque rhoncus, tortor lacus congue tellus, et sodales velit lorem et quam. Praesent sagittis, nisl a posuere tristique, velit dui mollis arcu, et hendrerit ex ligula at odio.</p>
      <p>Pellentesque sed massa libero. Curabitur dapibus tortor id porta venenatis. Nulla molestie nunc neque, ac varius orci pulvinar non. Vivamus tincidunt dolor vitae nisi dapibus, quis consequat libero malesuada. Donec eget ornare libero. Mauris a sollicitudin quam. Praesent quis nisi justo. Maecenas finibus dignissim nulla, ut viverra quam euismod id. Etiam porta ex ut pretium venenatis. Ut lobortis, eros ac tempor ornare, tellus sem tristique purus, vitae aliquam enim massa sodales urna. Morbi id pellentesque ipsum.</p>
      <p>Sed pharetra egestas efficitur. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Ut vitae aliquam est. Praesent tortor ligula, elementum quis imperdiet vel, sagittis iaculis ipsum. Donec tristique nec magna sed gravida. Quisque vitae luctus lectus, vulputate ornare lectus. Donec ac felis dolor. Phasellus diam turpis, luctus vel lorem eget, suscipit aliquam risus. Ut cursus sem diam, sed porta risus condimentum at. Aliquam eleifend venenatis consectetur. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Vestibulum sit amet lorem sit amet sem accumsan commodo. Duis varius, risus id auctor euismod, erat lectus aliquet arcu, sed laoreet mauris dolor nec felis. Suspendisse sed aliquam nisl, et scelerisque mauris. Nunc et nunc placerat velit viverra sodales.</p>
    </Container>
  );
};

const mapStateToProps = ( { Home, Products }) => {
  return { 
  }
}

export default connect(mapStateToProps)(PrivacyView);