import * as API from '../API/API';

export const REQUEST_HOME = 'REQUEST_HOME';
export const requestHome = () => {
  return {
    type: REQUEST_HOME,
    isFetching: true,
  }
}

export const RECEIVE_HOME = 'RECEIVE_HOME';
export const receiveHome = items => {
  return {
    type: RECEIVE_HOME,
    items: items,
  }
};

export const ERROR_RECEIVE_HOME = 'RECEIVE_HOME';
export const errorReceiveFolders = ( error ) => {
  return {
    type: ERROR_RECEIVE_HOME,
    error: error,
  }
}

export function fetchHome(){
  return ( dispatch, getState ) => {
    dispatch(requestHome())
    return API.getHome()
      .then( items => dispatch(receiveHome(items)) )
      .catch( error => dispatch(errorReceiveFolders(error)) )
  }
}