import * as API from '../API/API';

export const REQUEST_PAGES = 'REQUEST_PAGES';
export const requestPages = () => {
  return {
    type: REQUEST_PAGES,
    isFetching: true,
  }
}

export const RECEIVE_PAGES = 'RECEIVE_PAGES';
export const receivePages = items => {
  return {
    type: RECEIVE_PAGES,
    items: items.data.pages,
  }
};

export const ERROR_RECEIVE_PAGES = 'RECEIVE_PAGES';
export const errorReceivePages = ( error ) => {
  return {
    type: ERROR_RECEIVE_PAGES,
    error: error,
  }
}

export function fetchPages(){
  return ( dispatch, getState ) => {
    dispatch(requestPages())
    return API.getPages()
      .then( items => dispatch(receivePages(items)) )
      .catch( error => dispatch(errorReceivePages(error)) )
  }
}