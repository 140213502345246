import React from "react";
import { connect } from 'react-redux';
import Link from './Link';
import { setCurrentTab } from '../Actions/Config';
import history from '../history';

const TabLink = ({ href, className, children, display, "aria-current": ariaCurrent, "aria-controls": ariaControls, "data-value": dataValue, dispatch }) => {
    
  const onClick = ( event ) => {
    if (href.startsWith("http"))
      return;
    history.push(href);
    dispatch( setCurrentTab( dataValue ) );
    window.scrollTo(0, 0);
  };

  return (
    <Link
      onClick={onClick}
      href={href}
      className={className}
      aria-current={ariaCurrent}
      aria-controls={ariaControls}
      display={display}
    >
      {children}
    </Link>
  );
};

const mapStateToProps = ( { Config, Categories} ) => {
  return {
  };
}

export default connect(mapStateToProps)(TabLink);