// React Redux
import React from "react";
import { connect } from 'react-redux';

// Material UI
import { makeStyles } from '@material-ui/core/styles';

import Box from '@material-ui/core/Box';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';


const useStyles = makeStyles(() =>({
    tablecontainer:{
        margin: '1rem 0'
    },
    subtitulo:{
        color: 'rgba(0, 0, 0, 0.6)',
        fontWeight: '500'
    }
}));

const ShippingTable = ({ envios }) =>{

    const classes = useStyles();

    // Colores de estado: info.main, success.main

    return(
        <>
            <Typography variant="h6" component="h1">Historial de envíos</Typography>

            {envios && envios.length
            ?
            <TableContainer className={classes.tablecontainer} >
                <Table className={classes.table} aria-label="simple table">
                    <TableBody>
                        {envios.map((envio, index) =>(
                            <TableRow key={index}>
                                <TableCell component="th" scope="row">
                                    <Typography variant="subtitle2" gutterBottom> {envio.producto}</Typography>
                                </TableCell>
                                <TableCell align="left" className={classes.subtitulo}>Enviado el {envio.fecha}</TableCell>
                                <TableCell align="right">
                                    <Typography variant="subtitle2" gutterBottom><Box color="info.main">{envio.estado}</Box></Typography>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            :
            <Typography variant="body2" color="textSecondary"> No hay envíos actualmente. </Typography>
            }
        </>
    );
}

const mapStateToProps = ( { User }) => {
    return {
        envios: User.currentUser.shipments
    }
}

export default connect(mapStateToProps)(ShippingTable);