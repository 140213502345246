import React, { useState } from "react";
import { connect } from 'react-redux';
import styled from 'styled-components';
import Button from '@material-ui/core/Button';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import theme from '../../theme';
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { AutoRotatingCarousel, Slide } from "material-auto-rotating-carousel";
import { showWhoItWorksButton, hideWhoItWorksButton } from '../../Actions/Config';
import image1 from '../../Assets/Images/who-it-works-1.png';
import image2 from '../../Assets/Images/who-it-works-2.png';
import image3 from '../../Assets/Images/who-it-works-3.png';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';

const sliderStyle = {
  background: theme.palette.secondary.main,
  color: theme.palette.primary.main,
  padding: 0,
  '& *': {
    color: '#000',
  } 
}

const mediaStyle = {
  background: theme.palette.secondary.main,
}

const buttonProps = {
  style: {
    background: theme.palette.ternary.main,
    boxShadow: 'none',
    border: 0,
    '&:hover': {
      background: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    }
  }
}

const modalProps = {
  color: 'secondary',
}

const data = [
  {
    image: image1,
    title: 'Mejor gánalo',
    text: 'En Rocksel no compras productos, los consigues comprando casillas. ',
  },
  {
    image: image2,
    title: 'Acierta',
    text: 'Rocksel genera una casilla aleatoria ganadora, si está entre las que compraste, eres ganador.',
  },
  {
    image: image3,
    title: '¿Cuánto lo quieres?',
    text: 'Consigue productos con una mínima inversión, tú decides cuánto arriesgar para ganarlo.',
  }
];

const WhoItWork = ( { showButton, dispatch } ) => {

  const [handleOpen, setHandleOpen] = useState({ open: false });
  const handleClick = () => {
    setHandleOpen({ open: true });
    dispatch( hideWhoItWorksButton() );
  };

  const onClose = () => {
    setHandleOpen({ open: false });
    dispatch( showWhoItWorksButton() );
  }

  const isMobile = useMediaQuery("(max-width:600px)");
  return (
    <>
      <Container className={'who-it-works'}>

        <ReactCSSTransitionGroup
          transitionName="example"
          transitionEnterTimeout={500}
          transitionLeaveTimeout={300}>
          { showButton && (<StyledButton onClick={handleClick}><HelpOutlineIcon style={{marginRight:'0.5rem'}}/>{'Cómo funciona'}</StyledButton>)}
          
        </ReactCSSTransitionGroup>
        
        <AutoRotatingCarousel
          label={'Empezar'}
          open={handleOpen.open}
          onClose={onClose}
          onStart={onClose}
          autoplay={false}
          mobile={isMobile}
          ButtonProps={buttonProps}
          ModalProps={modalProps}
        >
          { data.length && data.map( ( item, key ) => {
            return (
              <Slide
                key={key}
                media={<img src={item.image} alt={''} />}
                mediaBackgroundStyle={mediaStyle}
                style={sliderStyle}
                title={(<TextStyled>{item.title}</TextStyled>)}
                subtitle={(<TextStyled>{item.text}</TextStyled>)}
              />
            );
          })}
        </AutoRotatingCarousel>
      </Container>
    </>
  );
}

const Container = styled.section`
  display: flex;
  justify-content: center;
  position: fixed;
  left: 0;
  bottom: 1rem;
  width: 100%;
  margin-left: auto;
  pointer-events: none;
  z-index: 1;
`; 

const TextStyled = styled.span`
  color: #222;
  font-size: inherit;
`;

const StyledButton = styled(Button)`
  background: ${theme.palette.ternary.main};
  color: ${theme.palette.ternary.contrastText};
  border-radius: 25px;
  padding: .5rem 2rem;
  pointer-events: initial;

  &:hover{
    background: ${theme.palette.primary.main};
    color: ${theme.palette.primary.contrastText};
  }
`;

const mapStateToProps = ( { Config } ) => {
  return {
    showButton: Config.showWhoItWorksButton,
  }
}

export default connect( mapStateToProps )(WhoItWork);