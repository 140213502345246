// React Redux
import React from "react";
import { connect } from 'react-redux';

// Material UI
import { makeStyles } from '@material-ui/core/styles';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';


const useStyles = makeStyles(() =>({
    tablecontainer:{
        margin: '1rem 0'
    },
    subtitulo:{
        color: 'rgba(0, 0, 0, 0.6)',
        fontWeight: '500'
    }
}));

const PaymentTable = ({ pagos }) =>{

    const classes = useStyles();

    return(
        <>
            <Typography  variant="h6" component="h1">Historial de pagos</Typography>

            {pagos && pagos.length
            ?
            <TableContainer className={classes.tablecontainer}>
                <Table className={classes.table} aria-label="simple table">
                    <TableBody>
                        {pagos.map((pago, index) =>(
                            <TableRow key={index}>
                                <TableCell component="th" scope="row">
                                    <Typography variant="caption" display="block" className={classes.subtitulo} gutterBottom> {pago.casillas} x casillas </Typography>
                                    <Typography variant="subtitle2" gutterBottom> {pago.producto}</Typography>
                                </TableCell>
                                <TableCell align="right" className={classes.subtitulo}>{pago.fecha}</TableCell>
                                <TableCell align="right">
                                    <Typography variant="subtitle2" gutterBottom>{pago.precio} €</Typography>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            :
            <Typography variant="body2" color="textSecondary"> No hay pagos actualmente. </Typography>
            }
        </>
    );
}

const mapStateToProps = ( { User }) => {
    return {
        pagos: User.currentUser.payments
    }
}

export default connect(mapStateToProps)(PaymentTable);