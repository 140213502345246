import React from "react";

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Link from './Link';
import Typography from '@material-ui/core/Typography';

import GoogleLogin from 'react-google-login';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';

import background from '../Assets/Images/auth-bg.png';
import logo from '../Assets/Images/logo-rocksel.png';

import SignIn from './SignIn';

const SignUpWithDialog =  function(props){
    return(
        <Dialog onClose={props.onClose} aria-labelledby="simple-dialog-title" open={props.open}>
            <IconButton aria-label="close" style={styles.closeButton} onClick={props.onClose}>
                <CloseIcon />
            </IconButton>
            <DialogContent  style={styles.dialogBackground}>
                <DialogContentText id="alert-dialog-description" style={styles.contenedorBotones}>
                    <div style={styles.topLogo}><Link href={'/'} ><img src={logo} alt={'Rocksel'}  css={{maxWidth: '100%'}} /></Link></div>
                    <Typography variant="body1" style={styles.topText} >Demuestra cuánto lo quieres.<br/>Únete.</Typography>
                
                    <GoogleLogin
                        clientId="595047643661-70tmtughapvde5h2am5p5nth2htm99d9.apps.googleusercontent.com"
                        render={renderProps => (
                            <Button variant="contained" style={styles.buttonGoogle} onClick={renderProps.onClick} disabled={renderProps.disabled}>Continúa con Google</Button>
                        )}
                        buttonText="Continúa con Google"
                        onSuccess={props.responseGoogle}
                        onFailure={props.responseGoogle}
                        cookiePolicy={'single_host_origin'}
                    />

                    <FacebookLogin
                        appId="571659923775838"
                        fields="name,email,picture"
                        callback={props.responseFacebook}
                        render={renderProps => (
                            <Button variant="contained" style={styles.buttonFacebook}  onClick={renderProps.onClick}>Continúa con Facebook</Button>
                        )}
                    />

                    <Typography variant="body1" style={styles.bottomText} >¿Ya tienes cuenta? <SignIn/> </Typography>
                </DialogContentText>
            </DialogContent>
        </Dialog>
    );
}

const styles = {
    dialogBackground: {
        backgroundImage: `url(${background})`,
        backgroundSize: 'cover',
        width: '340px',
        height: '460px'
    },
    closeButton: {
        position: 'absolute',
        top: '0.5rem',
        left: '0.5rem',
        color: '#9e9e9e',
    },
    topLogo:{
        color: 'white',
        textAlign: 'center',
        marginTop: '4rem',
        marginBottom: '1rem',
        flexGrow: 1
    },
    topText:{
        color: 'white',
        textAlign: 'center',
        flexGrow: 2,
        maxWidth: '43%',
        marginLeft: 'auto',
        marginRight: 'auto'
    },
    contenedorBotones:{
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: 'column',
        height: '100%',
        marginBottom: '0',
        justifyContent: 'flex-end'
    },
    buttonGoogle: {
        color: 'white',
        backgroundColor: '#db4437',
        borderRadius: '100px',
        display: 'flex',
        justifyContent: 'center',
        left: '2%',
        marginBottom: '1rem',
        width: '96%'
    },
    buttonFacebook: {
        color: 'white',
        backgroundColor: '#3b5998',
        borderRadius: '100px',
        display: 'flex',
        justifyContent: 'center',
        left: '2%',
        marginBottom: '1rem',
        width: '96%'
    },
    bottomText:{
        color: 'white',
        textAlign: 'center',
        marginBottom: '2rem'
    },
    regLink:{
        color: 'white',
        textDecoration: 'none'
    }
};

export default SignUpWithDialog;