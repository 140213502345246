import React from "react";
import { connect } from 'react-redux';

import styled from 'styled-components';
import { makeStyles } from '@material-ui/core/styles';

import AppBar from '@material-ui/core/AppBar';
import Avatar from '@material-ui/core/Avatar';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import ExitToApp from '@material-ui/icons/ExitToApp';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Toolbar from '@material-ui/core/Toolbar';
import WrapperMenu from './WrapperMenu';

import '../../Assets/CSS/Header.css';
import { openMenu } from '../../Actions/Config'; // Reducer
import { showSignUpWith,logoutUser, showProfile } from '../../Actions/User';
import Link from '../Link';
import logo from '../../Assets/Images/logo-rocksel.png';


const useStyles = makeStyles((theme) => ({
  offset: theme.mixins.toolbar
}));

const Header = ( { dispatch, user, isFetching } ) => {
  const classes = useStyles();

  const handleClick = () => {
    dispatch( openMenu() );
  }

  const logout = () => {
    console.log('cerrar sesión');
    dispatch( logoutUser() );
  }

  const loginButton = () => {
    if ( isFetching ) return (<CircularProgress color="inherit" size="1.75rem" />);
    if( user ) return (
      [<div style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'center'}}>
      <Avatar src={user.image} onClick={ () => dispatch( showProfile() ) }/>
      <IconButton color="inherit" onClick={logout}><ExitToApp/></IconButton>
      </div>]
    );   

    return (<ButtonStyled onClick={ () => dispatch( showSignUpWith() ) }>Accede</ButtonStyled>);
  }

  return(
    <>
      <AppBar position="fixed" color={'primary'} elevation={0} >
        <Toolbar className="topbar">
          <div className="topbar-left">
            <IconButton edge="start" color="inherit" aria-label="menu" onClick={handleClick}>
              <MenuIcon />
            </IconButton>
          </div>

          <Box className="topbar-center">
            <Link href={'/'}><img src={logo} alt={'Rocksel'}/></Link>  
          </Box>

          <Box className="topbar-right" component={'div'}>
            <Box component={'div'} display={{xs: 'none', md:'block' }}>
              {loginButton()}
            </Box>
          </Box>
        </Toolbar>
      </AppBar>
      <div className={classes.offset}></div>
      <WrapperMenu />
    </>
  ); 
}

const ButtonStyled = styled(Button)`
    font-size: 1.05rem;
    color: white;
`;

const mapStateToProps = ({ Config, User }) => {
  return { 
    user: User.currentUser,
    isFetching: User.isFetching
  }
}

export default connect( mapStateToProps )(Header);