// React Redux
import React from "react";
import { connect } from 'react-redux';
import styled from 'styled-components';

// Material UI
import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import Typography from '@material-ui/core/Typography';

// Rocksel
import ProfileEditAddress from './ProfileEditAddress';

const useStyles = makeStyles(() =>({
    root: {
        flexGrow: 1,
    },
    box:{
        border: 'solid 1px rgba(0, 0, 0, 0.12)',
        borderRadius: '4px',
        marginBottom: '1rem'
    },
    mb0:{
        marginBottom: '0'
    },
    circlebg:{
        backgroundColor: '#d4c8b7'
    },
    separar: {
        margin: '1rem 0'
    },
    btnredondo:{
        borderRadius: '25px',
        padding: '.5rem 2rem',
        margin: '1rem 0 1rem 0'
    }
}));

const AddressBox = ({ user }) =>{

    const classes = useStyles();

    return(
        <>
        <Box className={classes.box} p={2}>
            {user && user.shipping && user.name && user.name!=='' && user.shipping.address!=='' && user.shipping.postcode!=='' && user.shipping.city!=='' && user.shipping.state!=='' && user.shipping.phone!==''?
            [<Grid container wrap="nowrap" spacing={2}>
                <Grid item>
                    <Avatar className={classes.circlebg}>
                        <LocalShippingIcon color="primary"/>
                    </Avatar>
                </Grid>
                <Grid item xs>
                    <Typography gutterBottom variant="subtitle1" className={classes.mb0}> {user.name} </Typography>
                    <Typography variant="body2" color="textSecondary">
                        {user.shipping.address}<br/>
                        {user.shipping.postcode} {user.shipping.city} ({user.shipping.state}) <br/>
                        Tlfno. {user.shipping.phone}
                    </Typography>
                </Grid>
            </Grid>,
            <Typography variant="body1" color="textSecondary" className={classes.separar} gutterBottom>Configura tu dirección de envío.</Typography>,
            <ContenedorBotones>
                <Button className={classes.btnredondo}>BORRAR</Button>
                <ProfileEditAddress buttonText={'Editar'}/>
            </ContenedorBotones>]
            :
            [<Typography variant="body1" color="textSecondary" className={classes.separar} gutterBottom>No se ha añadido dirección. Configura tu dirección de envío.</Typography>,
            <ContenedorBotones>
                <ProfileEditAddress buttonText={'Añadir'} dialogTitle={'Añadir datos de envío'}/>
            </ContenedorBotones>]
            }
        </Box>
        </>
    );
}

const ContenedorBotones = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
`;

const mapStateToProps = ({ User }) => {
    return {
        user: User.currentUser
    }
}

export default connect(mapStateToProps)(AddressBox);