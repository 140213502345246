import React from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from './Link';

const useStyles = makeStyles((theme) => ({
  root: {
    
  },
  link: {
    textTransform: 'uppercase',
    fontSize: theme.typography.pxToRem(10),
    letterSpacing: '1.5px',
    fontWeight: 500,
    color: 'rgba(0, 0, 0, 0.6)',
    margin: '0.1rem 0 1rem'
  }
}));

const BreadcrumbsComponent = ( { items } ) => {
  const classes = useStyles();
  return (
    <Breadcrumbs className={classes.link} aria-label="breadcrumb">
      { items.length && items.map( ( item, key ) => {
        return ( <Link key={key} className={classes.link} href={item[1]}>
          {item[0]}
        </Link> );
      } )}
    </Breadcrumbs>
  );
}

const mapStateToProps = ( state ) => {
  return state;
}

export default connect(mapStateToProps)(BreadcrumbsComponent);