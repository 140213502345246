import React from "react";
import { connect } from 'react-redux';
import styled from 'styled-components';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import ProductCard from "../Components/ProductCard";

const ProductList = ({ products, title, columns, number, offset, autoplay }) => {

  const state = {
    offset: offset || 0,
    number: number || 2,
    columns: columns || 2,
    autoplay: autoplay || false,
    products: [],
  };

  const getColumnAtts = () => {
    switch( state.columns ){
      case 2:
        return { xs: 6, };
      case 3:
        return { xs: 6, md: 4, };
      case 4:
        return { xs: 6, md: 3, };
      default:
        return { xs: 6, md: 4, lg: 2 };
    }
  }
  
  const getProducts = () => {
    state.products = products.slice( state.offset, state.offset + state.number );
  }

  const nextPage = () => {
    let offset = state.offset + state.number;
    state.offset = ( state.products.length <= offset ) ? 0 : offset;
  }

  const prevPage = () => {
    let offset = state.offset - state.number;
    state.offset = ( 0 <= offset ) ? offset : 0;
  }

  const goPage = ( page ) => {
    state.offset = state.number * parseInt( page );
  }

  const columnAtts = getColumnAtts();
  const productsFiltered = getProducts();

  if( state.autoplay ){
    setInterval( () => nextPage(), state.autoplay );
  }

  return(
    <>
      <ContainerStyled component={"section"} maxWidth="md">
        <SectionTitle component={'h2'} align={'center'}>{title}</SectionTitle>
        <Grid container spacing={4}>
          {state.products.map( product => (
            <Grid key={product.name} item {...columnAtts}>
              <ProductCard product={product} />
            </Grid>
          ))}
        </Grid>
      </ContainerStyled>
    </>
  ); 
}



const SectionTitle = styled(Typography)`
  font-size: 1.3rem !important;
  padding: 1rem 0 2rem;
`;

const ContainerStyled = styled(Container)`
  padding: 3rem;
`;

const mapStateToProps = ( state ) => { return state; }

export default connect(mapStateToProps)(ProductList);