import React, { useState } from "react";
import { connect } from 'react-redux';
import theme from '../../theme';
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { AutoRotatingCarousel, Slide } from "material-auto-rotating-carousel";
import { showWhoItWorksButton } from '../../Actions/Config';
import image1 from '../../Assets/Images/welcome1.png';
import image2 from '../../Assets/Images/welcome2.png';
import image3 from '../../Assets/Images/welcome3.png';
import image4 from '../../Assets/Images/welcome4.png';
import image5 from '../../Assets/Images/welcome5.png';

const sliderStyle = {
  background: theme.palette.secondary.light,
  color: theme.palette.primary.main,
  padding: 0,
  '*': {
    maxWidth: '100%',
  }
}

const mediaStyle = {
  background: theme.palette.secondary.light,
  height: '82%',
}

const buttonProps = {
  style: {
    background: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    boxShadow: 'none',
    padding: '.8rem 2rem',
    border: 0,
    '&:hover': {
      background: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
  }
}

const data = [
  {
    image: image1,
    title: '',
    text: '',
  },
  {
    image: image2,
    title: '',
    text: '',
  },
  {
    image: image3,
    title: '',
    text: '',
  },
  {
    image: image4,
    title: '',
    text: '',
  },
  {
    image: image5,
    title: '',
    text: '',
  },
];

const Welcome = ( { dispatch } ) => {
  const [handleOpen, setHandleOpen] = useState({ open: true });
  const isMobile = useMediaQuery("(max-width:600px)");

  const onClose = () => {
    setHandleOpen({ open: false });
    dispatch( showWhoItWorksButton() );
  };

  return (
    <AutoRotatingCarousel
      label={'EMPEZAR EN ROCKSEL'}
      open={handleOpen.open}
      onClose={onClose}
      onStart={onClose}
      autoplay={false}
      mobile={isMobile}
      ButtonProps={buttonProps}
      style={{
        fontSize: '3rem',
      }}
    >
      { data.length && data.map( ( item, key ) => {
        return (
          <Slide
            key={key}
            media={<img src={item.image} alt={''} />}
            mediaBackgroundStyle={mediaStyle}
            style={sliderStyle}
            title={item.title}
            subtitle={item.text}
          />
        );
      })}
    </AutoRotatingCarousel>
  );
}

const mapStateToProps = ( { Config } ) => {
  return {
    
  }
}

export default connect( mapStateToProps )(Welcome);