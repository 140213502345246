import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from "react-router-dom";
import App from './App';
import * as serviceWorker from './serviceWorker';
import { createStore, applyMiddleware, compose } from 'redux'
import { Provider } from 'react-redux'
import thunk from 'redux-thunk'
import logger from 'redux-logger'
import createSagaMiddleware from 'redux-saga'
import ReduxPromise from 'redux-promise'
import reducers from './Reducers'
import { fetchPages } from './Actions/Pages';
import { fetchHome } from './Actions/Home';
import { fetchProducts } from './Actions/Products';
import { fetchCategories } from './Actions/Categories';
import history from './history';
import './index.css';
import 'react-redux-toastr/lib/css/react-redux-toastr.min.css'
import ReduxToastr from 'react-redux-toastr'
import {loadState, saveState} from './API/localstorage'

const initialData = loadState()
const sagaMiddleware = createSagaMiddleware();
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore( reducers, composeEnhancers( applyMiddleware(sagaMiddleware, ReduxPromise, thunk, logger) ) );

store.subscribe( function () {
  saveState(store.getState())
})

store.dispatch( fetchPages() );
store.dispatch( fetchHome() );
store.dispatch( fetchProducts() );
store.dispatch( fetchCategories() );

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <Router history={history} >
        <App />
      </Router>
      <ReduxToastr
        timeOut={6000}
        newestOnTop={false}
        preventDuplicates
        position="bottom-right"
        getState={(state) => state.Alerts} // This is the default
        transitionIn="fadeIn"
        transitionOut="fadeOut"
        closeOnToastrClick/>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
