import { createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
  palette: {
    primary: {
      light: '#fafafa',
      main: '#000',
      dark: '#000',
      contrastText: '#fff',
    },
    secondary: {
      light: '#FFF',
      main: '#FAFAFA',
      dark: '#CCC',
      contrastText: '#000',
    },
    ternary: {
      light: '#d4c8b7',
      main: '#d4c8b7',
      dark: '#756a5b',
      contrastText: '#000',
    },
    black: {
      100: 'rgba(0, 0, 0, 1)',
      80: 'rgba(0, 0, 0, 0.8)',
      60: 'rgba(0, 0, 0, 0.6)',
      40: 'rgba(0, 0, 0, 0.4)',
      20: 'rgba(0, 0, 0, 0.2)',
    },
  },
  status: {
    danger: 'orange',
  },
  typography: {
    fontFamily: [
      'Poppins',
      'sans-serif',
    ].join(','),
  },
});

export default theme;